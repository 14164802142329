import React from 'react';
import styled from '@emotion/styled';
import { UseRefinementListProps, useRefinementList } from 'react-instantsearch';

import { Icon, Box, Checkbox, colors, Text, Divider } from '../../../design-system';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { ToolTipFilters } from './ToolTipFilters';
import { detailedInfosProductShape } from './FiltersShapeDescription.data';
import { theme } from '../../../design-system/systemprovider/constants';

type Props = {
  suffix?: string;
  shouldHaveTooltip?: boolean;
};

const StyledStandardFilter = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  .standard-filter-item__container {
    display: block;
    margin-top: ${({ isMobile }) => (isMobile ? theme.space.s : 'unset')};
  }
  .standard-filter__item {
    height: 34px;
    @media (hover: hover) {
      &:hover {
        background: ${colors.BACKGROUND};
      }
    }
  }
  .standard-filter-content__container {
    display: grid;
    grid-auto-flow: column;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
  }
  .standard-filter__name {
    font-size: 1.4rem;
    line-height: 1.8rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
  }
  .standard-filter-item-count__container {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .standard-filter__count {
    width: 34px;
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin: 0;
    text-align: right;
  }
  .standard-filter__name--is-refined,
  .standard-filter__count--is-refined {
    font-weight: bold;
  }
`;

export const StandardFilter: React.FC<UseRefinementListProps & Props> = ({
  attribute,
  suffix = '',
  shouldHaveTooltip,
  ...props
}) => {
  const { isMobile } = useMediaQueries();
  const { items, refine, canToggleShowMore, isShowingMore, toggleShowMore } = useRefinementList({
    ...props,
    attribute,
    sortBy: ['name:asc'],
    showMore: isMobile ? false : true,
    limit: isMobile ? 100 : 5,
    showMoreLimit: 200,
  });

  return (
    <StyledStandardFilter isMobile={isMobile}>
      {items.map((item, index) => {
        const tooltipData = detailedInfosProductShape.find(
          (data) => data.shapeName.toLowerCase() === item.label.toLowerCase()
        );

        return (
          <div className="standard-filter-item__container" key={`filter-${item.label}-${index}`}>
            <div id={`filter-${attribute}-${item.value}`} className="standard-filter__item">
              <Checkbox
                id={item.label}
                onChange={() => {
                  refine(item.value);
                }}
                checked={item.isRefined}
                isFilterCheckBox={true}
              >
                <div className="standard-filter-content__container">
                  <p
                    className={`standard-filter__name ${item.isRefined ? 'standard-filter__name--is-refined' : ''}`}
                  >
                    {`${item.label} ${suffix}`}
                  </p>
                  <div className="standard-filter-item-count__container">
                    {shouldHaveTooltip && tooltipData && (
                      <ToolTipFilters
                        picto={tooltipData.picto}
                        shapeName={tooltipData.shapeName}
                        shapeDescription={tooltipData.shapeDescription}
                      />
                    )}
                    <p
                      className={`standard-filter__count ${item.isRefined ? 'standard-filter__count--is-refined' : ''}`}
                    >
                      ({item.count})
                    </p>
                  </div>
                </div>
              </Checkbox>
            </div>
            {isMobile && <Divider />}
          </div>
        );
      })}
      {canToggleShowMore && (
        <Box
          py="s"
          pr="s"
          display="flex"
          alignItems="flex-end"
          onClick={toggleShowMore}
          id={`filter-${attribute}-see-more`}
        >
          {isShowingMore ? <Icon name="chevronUp" /> : <Icon name="chevronDown" />}
          <Box pl="s">
            <Text pl="xs">Voir {isShowingMore ? 'moins' : 'plus'}</Text>
          </Box>
        </Box>
      )}
    </StyledStandardFilter>
  );
};
