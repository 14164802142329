import styled from '@emotion/styled';
import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModal, openModalNew } from '../../common/actions';
import { colors } from '../../../design-system';

type Props = {
  picto: React.ReactNode;
  shapeName: string;
  shapeDescription: string;
};

const StyledToolTipFilters = styled.button`
  padding: 0;
  border: none;
  background-color: unset;
  cursor: pointer;
`;

const StyledToolTipContent = styled.div`
  padding: 40px 24px 24px 24px;
  max-width: 80vw;
  .tooltip__shape-name {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    margin: 0 0 8px 0;
  }
  .tooltip__description {
    max-width: 400px;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 16px 0;
  }
  .tooltip-description__picto {
    background-color: ${colors.BACKGROUND};
    > svg {
      display: block;
      margin: auto;
    }
  }
`;

export const ToolTipFilters = ({ picto, shapeDescription, shapeName }: Props) => {
  const dispatch = useDispatch();
  const onToolTipClick = () => {
    dispatch(closeModal());
    dispatch(
      openModalNew({
        content: (
          <StyledToolTipContent className="tooltip-description__container">
            <p className="tooltip__shape-name">{shapeName}</p>
            <p className="tooltip__description">{shapeDescription}</p>
            <div className="tooltip-description__picto">{picto}</div>
          </StyledToolTipContent>
        ),
        preset: 'inheritWidth',
      })
    );
  };
  return (
    <StyledToolTipFilters className="tooltip__container" onClick={onToolTipClick}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_628_1369)">
          <path
            d="M9.08984 18C4.08984 18 0.0898438 14 0.0898438 9C0.0898438 4 4.08984 0 9.08984 0C14.0898 0 18.0898 4 18.0898 9C18.0898 14 14.0898 18 9.08984 18ZM9.08984 17C13.4898 17 17.0898 13.4 17.0898 9C17.0898 4.6 13.4898 1 9.08984 1C4.68984 1 1.08984 4.6 1.08984 9C1.08984 13.4 4.68984 17 9.08984 17ZM8.68984 5.2V4.1H9.78984V5.2H8.68984ZM6.68984 7.3V6.5H9.78984V12H11.8898V12.8H6.58984V12H8.78984V7.3H6.68984Z"
            fill="black"
          />
        </g>
      </svg>
    </StyledToolTipFilters>
  );
};
