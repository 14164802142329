const ecartItem = `{
  originalPrice
  storePrice
  productRef
  colorRef
  sku
  productName
  colorLabel
  detailedColorLabel
  rowBaseTotal
  rowTotal
  quantity
  size
  bandSize
  cupSize
  promotionPercentage
  promotionLabel
  status
  stockQuantity
  eReservation
}`;

const ecart = `{
  items ${ecartItem}
  total
  storeInfo {
    id
    name
    address {
      line1
      line2
      city
      postalCode
      countryCode
    }
    mainPhone
    hours {
      monday {
        openIntervals  {
          start
          end
        }
        isClosed
      }
      tuesday {
        openIntervals  {
          start
          end
        }
        isClosed
      }
      wednesday {
        openIntervals  {
          start
          end
        }
        isClosed
      }
      thursday {
        openIntervals  {
          start
          end
        }
        isClosed
      }
      friday {
        openIntervals  {
          start
          end
        }
        isClosed
      }
      saturday {
        openIntervals  {
          start
          end
        }
        isClosed
      }
      sunday {
        openIntervals  {
          start
          end
        }
        isClosed
      }
      holidayHours {
        openIntervals {
            start
            end
        }
        isClosed
        date
        isRegularHours
      }
      reopenDate
    }
  }
}`;

const GiftCardFields = `{
  itemId
  price
  from
  to
  email
  date
  message
}`;

const cartItem = `{
  productRef
  colorRef
  sku
  productName
  colorLabel
  detailedColorLabel
  rowBaseTotal
  rowTotal
  quantity
  size
  bandSize
  cupSize
  promotionPercentage
  promotionLabel
  status
  stockQuantity {
    stockQuantity
  }
  freeProduct
  giftMessage
  promoMessages
  itemCouponAmount
  promoPrice
  isDigital
  tax
  label1
  label2
  gcFields ${GiftCardFields}
}`;

const cart = `{
  cartId
  items ${cartItem}
  couponCode
  couponAccepted
  rejectCouponMessage
  shippingType
  shippingAmount
  freeShipping
  notifications {
    type
    title
    message
  }
  total
  isGiftEnabled
  isValidated
  isDigital
  originalBaseTotal
  totalCouponAmount
  totalDiscount
}`;

const address = `{
  id
  name
  company
  idTva
  siret
  firstName
  lastName
  street
  streetAdditional
  postal
  city
  country
  phone
}`;

const user = `{
  email
  firstName
  lastName
  optInEmail
  optInSMS
  createdAt
  phone
  prefix
  dob
  addresses ${address}
  shippingAddressId
  billingAddressId
  errorCode
  emailSHA256
  emailMD5
  lastOrderDate
  error
}`;

const shippingTypes = `{
  label
  amount
}`;

const wishlistItem = `{
  productRef
  colorRef
  sku
  productName
  colorLabel
  detailedColorLabel
  originalPrice
  storePrice
  size
  bandSize
  cupSize
  promotionPercentage
  promotionLabel
  status
  stockQuantity
  sizeVariants {
    sku
    originalPrice
    storePrice
    promotionPercentage
    stockQuantity
    cupSize
    bandSize
    size
  }
  tax
  label1
  label2
}`;

const wishlist = `{
  wishlistId
  items ${wishlistItem}
}`;

export default {
  getOrder: `
    query getOrder($orderNumber: Int!, $migrated: Boolean!) {
      order(orderNumber: $orderNumber, migrated: $migrated) {
        orderNumber
        createdAt
        status
        shippingAddress ${address}
        billingAddress ${address}
        items {
          productRef
          colorRef
          sku
          productName
          detailedColorLabel
          rowBaseTotal
          rowTotal
          quantity
          size
          bandSize
          cupSize
          tax
        }
        total
        originalBaseTotal
        totalCouponAmount
        totalDiscount
        shippingAmount
        shippingType
        storeInfos {
          address1
          address2
          city
          countryCode
          id
          name
          postalCode
        }
        pickupStationInfos {
          id
          name
          line1
          line2
          line3
          postalCode
          city
        }
        trackingNumber
        tracking {
          trackingNumber
        }
        paymentMethod
        cardSummary
        paymentDetails {
          method
          cardSummary
          amount
        }
        isDigital
      }
    }
  `,
  getOrders: `
    query getOrders {
      orders {
        orderNumber
        shippingType
        status
        total
        createdAt
        trackingNumber
        tracking
        migrated
      }
    }
  `,
  getProduct: `
    query getProduct($productRef: String!, $colorRef: String!) {
      product(productRef: $productRef, colorRef: $colorRef) {
        productRef
        productName
        productDescription
        productComposition
        status
        eReservation
        colorRef
        colorLabel
        realColorRef
        detailedColorLabel
        cleaningAdvice
        originalPrice
        storePrice
        promotionPercentage
        promotionLabel
        promoLongLabel
        promoBColor
        promoTColor
        isOutlet
        images
        collectionName
        crossSellType
        categories {
          lvl0
          lvl1
          lvl2
          lvl3
        }
        colorVariants {
          colorRef
          colorLabel
          realColorRef
          detailedColorLabel
          status
          label1
          label2
        }
        sizeVariants {
          sku
          originalPrice
          storePrice
          promotionPercentage
          stockQuantity
          cupSize
          bandSize
          size
          storesAvailable {
            quantity
            store
          }
        }
        model {
          position
          name
          size
        }
        moreDetails
        videoUrl
        isRecyclable
        isDigital
        contentRecycledMaterials
        recyclability
        rejectsMicrofibers
        toxicity
        typology
        traceability
        tax
        label1
        label2
      }
    }
  `,
  getCart: `
    query getCart {
      cart ${cart}
      shippingTypes ${shippingTypes}
      wishlist ${wishlist}
    }
  `,
  getIsRegistered: `
    query getIsRegistered($email: String!) {
      isRegistered(email: $email){
        registered
      }
    }`,
  addCoupon: `
    mutation addCoupon($couponCode: String!) {
      addCoupon(couponCode: $couponCode) ${cart}
    }
  `,
  addToCart: `
    mutation addToCart($product: AddToCartRequest!) {
      addToCart(requestValues: $product) ${cart}
    }
  `,
  addGiftCard: `
    mutation addGiftCard($giftCard: AddGiftCardRequest!) {
      addGiftCard(requestValues: $giftCard) ${cart}
    }
  `,
  removeGiftCard: `
    mutation removeGiftCard($itemId: String!) {
      removeGiftCard(itemId: $itemId) ${cart}
    }
  `,
  updateGiftCard: `
    mutation updateGiftCard($itemId: String!, $giftCard: AddGiftCardRequest!) {
      updateGiftCard(itemId: $itemId, requestValues: $giftCard) ${cart}
    }
  `,
  addToECart: `
    mutation addToECart($product: AddToCartRequest!) {
      addToECart(requestValues: $product) ${ecart}
    }
  `,
  addShippingType: `
    mutation addShippingType($type: ShippingType!) {
      addShippingType(type: $type) ${cart}
    }
    `,
  deleteCoupon: `
    mutation deleteCoupon($couponCode: String!) {
      deleteCoupon(couponCode: $couponCode) ${cart}
    }
  `,
  eReservation: `
    mutation eReservation($firstName: String!, $lastName: String!, $email: String!, $phone: String!) {
      eReservation(firstName: $firstName, lastName: $lastName, email: $email, phone: $phone) {
        reservationId
      }
    }
 `,
  initEreservation: `mutation initEreservation($product: AddToCartRequest!, $boutiqueId: String!) {
  initEreservation(requestValues: $product, boutiqueId: $boutiqueId) ${ecart}
}
`,
  paypalECS: `
    mutation paypalECS($gaClientId: String) {
        paypalECS(gaClientId: $gaClientId) {
          sessionValidity
          merchantAccount
          paymentAmount
          currencyCode
          skinCode
          merchantReference
          brandCode
          merchantSig
        }
    }
 `,
  updateQuantity: `
    mutation updateCart($product: UpdateProductRequest!) {
      updateCart(requestValues: $product) ${cart}
    }
  `,
  mergeCart: `
    mutation mergeCart($cartId: String!) {
      mergeCart(cartId: $cartId) ${cart}
    }
  `,
  removeItem: `
    mutation deleteProduct($product: DeleteProductRequest!) {
      deleteProduct(requestValues: $product) ${cart}
    }
  `,
  bulkRemove: `
    mutation deleteProducts($products:[ DeleteProductRequest!]!) {
      deleteProducts(requestValues: $products) ${cart}
    }
  `,
  bulkECartRemove: `
    mutation deleteECartProducts($products:[ DeleteProductRequest!]!) {
      deleteECartProducts(requestValues: $products) ${ecart}
    }
  `,
  getUser: `
    query getUser {
      user ${user}
      cart ${cart}
      shippingTypes ${shippingTypes}
      wishlist ${wishlist}
    }
  `,
  getUserDetails: `
    query getUserDetails {
      user ${user}
    }
  `,
  updateUser: `
    mutation updateUser($user: UpdateUserRequest!) {
      user(attributes: $user) ${user}
    }
  `,
  setCheckoutAddresses: `
    mutation checkoutAddress($shipping: UserAddressRequest, $billing: UserAddressRequest!, $gaClientId: String, $giftMessage: String) {
      checkoutAddress(shipping: $shipping, billing: $billing, gaClientId: $gaClientId, giftMessage: $giftMessage) {
        cart ${cart}
        paymentMethods
        token
      }
    }
  `,
  setCheckoutStore: `
    mutation setCheckoutStore($storeId: String!, $billing: UserAddressRequest!, $gaClientId: String, $giftMessage: String) {
      checkoutStore(storeId: $storeId, billing: $billing, gaClientId: $gaClientId, giftMessage: $giftMessage) {
        cart ${cart}
        paymentMethods
        token
      }
    }
 `,
  setCheckoutPickupStation: `
    mutation setCheckoutPickupStation($pickupStation: pickupStationInput, $contactPhone: String!, $billing: UserAddressRequest!, $gaClientId: String, $giftMessage: String) {
      checkoutPickupStation(pickupStation: $pickupStation, contactPhone: $contactPhone, billing: $billing, gaClientId: $gaClientId, giftMessage: $giftMessage) {
        cart ${cart}
        paymentMethods
        token
      }
    }
 `,
  contact: `
    mutation contact($form: ContactUsRequest) {
      contactUs(requestValues: $form) {
        success
      }
    }
 `,
  sendAffiliationForm: `
 mutation affiliation($form: AffiliateForm) {
  affiliation(requestValues: $form) {
    success
  }
 }
`,
  getFbUsername: `
    query getFbUsername($payload: Social) {
      socialFb(payload: $payload) {
        username
      }
    }
  `,
  getGoogleUsername: `
    query getGoogleUsername($payload: SocialGoogle) {
      socialGoogle(payload: $payload) {
        username
      }
    }
  `,
  getAppleUsername: `
    query getAppleUsername($payload: SocialApple) {
      socialApple(payload: $payload) {
        username
      }
    }
  `,
  getColissimoToken: `
    query getColissimoToken {
      colissimoToken {
        success
        message
      }
    }
  `,
  getPickupStations: `
    query getPickupStations($token: String!, $isSearchByText: Boolean!, $searchText: String!, $position: Position ) {
      pickupStations(token: $token, isSearchByText: $isSearchByText, searchText: $searchText, position: $position) {
        id
        name
        line1
        line2
        line3
        postalCode
        city
        accessForPRM
        parking
        exceptionalClosings {
          start
          end
        }
        displayCoordinate {
          latitude
          longitude
        }
        hours {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
      }
    }
  `,
  getStoresByText: `
    query getStoresByText($text: String!) {
      storeSearchText(text: $text) {
        stores {
          id
        }
        geoCoordinate {
          latitude
          longitude
        }
      }
    }
  `,
  getStoresByPosition: `
    query getStoresByPosition($lat: Float!, $lng: Float!, $bounds: Bounds!) {
      storeSearchPosition(lat: $lat, lng: $lng, bounds: $bounds) {
        stores {
          id
        }
        geoCoordinate {
          latitude
          longitude
        }
      }
    }
  `,
  subscribeNewsletter: `
    mutation subscribeNewsletters($payload: SubscriptionGateInput!) {
      subscribeNewsletters(gateInput: $payload) {
        success
      }
    }
  `,
  addToWishlist: `
    mutation addToFavourites($product: AddToFavouritesRequest!) {
      addToFavourites(requestValues: $product) ${wishlist}
    }
  `,
  removeFromWishlist: `
    mutation deleteFromFavourites($product: DeleteWishlistItemRequest!) {
      deleteFromFavourites(requestValues: $product) ${wishlist}
    }
  `,
  mergeWishlist: `
    mutation mergeFavourites($wishlistId: String!) {
      mergeFavourites(wishlistId: $wishlistId) ${wishlist}
    }
  `,
  moveToWishlistFromCart: `
    mutation moveToFavourites($product: ProductToMove!) {
      moveToFavourites(requestValues: $product) {
        cart ${cart}
        wishlist ${wishlist}
      }
    }
  `,
  moveToCartFromWishlist: `
    mutation moveToCart($product: ProductToMove!) {
      moveToCart(requestValues: $product) {
        cart ${cart}
        wishlist ${wishlist}
      }
    }
  `,
  mergeCartAndWishlist: `
    mutation mergeCartAndWishlist($cartId: String!, $wishlistId: String!) {
      mergeCart(cartId: $cartId) ${cart}
      mergeFavourites(wishlistId: $wishlistId) ${wishlist}
    }
  `,
  reserveOrderNumber: `
    mutation reserveOrderNumber($token: String!) {
      reserveOrderNumber(requestValues: $token) {
        success
        orderNumber
        amount
      }
    }
  `,
} as const;
