import React, { useState } from 'react';
import styled from '@emotion/styled';

import { CmsSearchMostWanted } from '../../cms/types';
import { Spinner, breakpoints, colors } from '../../../design-system';
import { SearchMostWantedProducts } from './SearchMostWantedProducts';

type Props = {
  data: CmsSearchMostWanted | undefined;
};

const PRODUCT_CARD_WIDTH = 172;
const PRODUCT_CARD_HEIGHT = 320;

const StyledContainer = styled.div<{ isLoading: boolean }>`
  overflow-y: hidden;

  @media (min-width: ${breakpoints.L}px) {
    flex: 1 0 0;
  }

  .search-most-wanted-title {
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 16px 0 8px 0;
    font-size: 1.4rem;
    line-height: 21px;
    font-weight: 700;
    color: ${colors.BLACK};
  }

  .search-products-wrapper {
    display: ${(props) => (props.isLoading ? 'none' : 'flex')};
    gap: 16px;
  }

  .search-product-card {
    min-width: ${PRODUCT_CARD_WIDTH}px;
    min-height: ${PRODUCT_CARD_HEIGHT}px;
  }

  .spinner-block {
    display: ${(props) => (props.isLoading ? 'flex' : 'none')};
    height: ${PRODUCT_CARD_HEIGHT}px;
    align-items: center;
    justify-content: center;
  }
`;

export const SearchMostWanted = ({ data }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const { most_wanted_title } = data?.primary ?? {};
  const { items } = data ?? {};

  return !data ? null : (
    <StyledContainer id="search-most-wanted-block" isLoading={isLoading}>
      {most_wanted_title && <h2 className="search-most-wanted-title">{most_wanted_title}</h2>}
      <div className="spinner-block">
        <Spinner size={40} color={colors.BLACK} />
      </div>
      <div className="search-products-wrapper">
        {items?.map((item, index) => (
          <SearchMostWantedProducts
            key={item.most_wanted_product_ref}
            productRef={item.most_wanted_product_ref}
            isLastProduct={items.length === index + 1}
            setIsLoading={setIsLoading}
          />
        ))}
      </div>
    </StyledContainer>
  );
};
