import React from 'react';
import styled from '@emotion/styled';

import { colors, Icon } from '../../../design-system';
import { isEcoLabel } from '../../catalog/utils';

type Props = {
  rankLabel?: string;
  attributeLabel?: string;
};

const StyledRankAttributeLabelContainer = styled.div<{ isEcoLabel: boolean }>`
  display: flex;

  .product-rank-label,
  .product-attribute-label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 4px;
  }

  .product-additional-label-block {
    display: flex;
    gap: 8px;
  }

  .product-rank-label {
    color: ${colors.WHITE};
    background-color: ${colors.BLACK};
    gap: 4px;
  }

  .product-attribute-label {
    height: 20px;
    color: ${(props) => (props.isEcoLabel ? colors.WHITE : colors.MAIN_GREY)};
    background-color: ${(props) => (props.isEcoLabel ? colors.GREEN_LABEL : colors.LIGHT2)};
  }

  .icon-label {
    margin-top: -2px;
  }
`;

export const RankAttributeLabel = ({ rankLabel, attributeLabel }: Props) => {
  return !(rankLabel || attributeLabel) ? null : (
    <StyledRankAttributeLabelContainer isEcoLabel={isEcoLabel(attributeLabel)}>
      <div className="product-additional-label-block">
        {rankLabel && (
          <span className="product-rank-label">
            {rankLabel.toLocaleLowerCase() === 'bestseller' && (
              <Icon className="icon-label" name="star" size={12} color={colors.WHITE} />
            )}
            {rankLabel}
          </span>
        )}
        {attributeLabel && (
          <span className="product-attribute-label">
            {isEcoLabel(attributeLabel) && <Icon className="icon-label" name="leaf" size={16} />}
            {attributeLabel}
          </span>
        )}
      </div>
    </StyledRankAttributeLabelContainer>
  );
};
