import * as qs from 'qs';
import TagManager from 'react-gtm-module';

import { persistData, getIsClient, findDevice, getPersistedData } from '../common/utils';
import { getProductLink, getProductImageLink } from '../product/utils';
import {
  InitParams,
  TaxParams,
  CustomerStatus,
  User,
  Cart,
  Order,
  TrackingProduct,
  DataLayer,
  Events,
} from './types';
import paths from '../routing/paths';
import accountPaths from '../account/paths';
import { AWS_IDENTITY_POOL_ID } from '../api/constants';
import { urlToSearchState } from '../search/utils';
import { Cart as CartDetail, CartStep, ShippingTypes } from '../cart/types';

declare global {
  interface Window {
    _chtlcid: {
      vid: string;
    };
  }
}

export function calculateAge(birthDateString: string): number {
  const [month, day, year] = birthDateString.split('/');
  const birthDate = new Date(`${day}/${month}/${year}`);
  const now = new Date();
  let years = now.getFullYear() - birthDate.getFullYear();

  if (
    now.getMonth() < birthDate.getMonth() ||
    (now.getMonth() === birthDate.getMonth() && now.getDate() < birthDate.getDate())
  ) {
    years--;
  }

  return years;
}

export function formatDate(date: Date): string {
  const timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
  date.setTime(date.getTime() - timeOffsetInMS);
  return date.toISOString().split('T')[0];
}

export function changeDayMonthOrder(dateString: string): string {
  const [day, month, year] = dateString.split('/');
  return `${month}/${day}/${year}`;
}

export const DEFAULT_TAX_PERCENTAGE = 20;

export function getTaxRate(taxRate?: number): number {
  return taxRate ?? DEFAULT_TAX_PERCENTAGE;
}

export function getTaxFree({ amount, taxRate }: TaxParams): number {
  return Number.parseFloat((amount / (1 + getTaxRate(taxRate) / 100)).toFixed(2));
}

export function getTax({ amount, taxRate }: TaxParams): number {
  return amount - getTaxFree({ amount, taxRate });
}

export function getDevice(): string {
  if (!getIsClient()) {
    return 'node';
  }

  const device = findDevice(navigator.userAgent);
  return ['mobile', 'tablet', 'desktop'][device];
}

export function getCatalog({
  products,
  isCart,
}: {
  products: any[];
  isCart?: boolean;
}): TrackingProduct[] {
  return products.map((product) => getTrackingProduct({ product, isCart }));
}

// refacto getTrackingProduct function according GTM expected behavior and update type

export function getTrackingProduct({
  product,
  isCart,
  isEresaIntent,
}: {
  product: any;
  isCart?: boolean;
  isEresaIntent?: boolean;
}): TrackingProduct {
  return {
    simple_sku: product.sku
      ? `${product.productRef}\\${product.sku}`
      : isEresaIntent
        ? undefined
        : product.sizeVariants?.[0]?.sku
          ? `${product.productRef}\\${product.sizeVariants[0].sku}`
          : undefined,
    configurable_sku: `${product.productRef}-${product.colorRef}`,
    name: product.productName,
    url_page: `${location.origin}${getProductLink({
      productRef: product.productRef,
      colorRef: product.colorRef,
      productName: product.productName,
      colorLabel: product.colorLabel,
    })}`,
    url_picture: getProductImageLink({
      productRef: product.productRef,
      colorRef: product.colorRef,
      productName: product.productName,
      position: 1,
      width: 768,
    }),
    brand: 'Darjeeling',
    line: product.collectionName,
    color: product.detailedColorLabel,
    promotions: isCart ? undefined : product.promotionLabel,
    quantity: isCart ? product.quantity : undefined,
    size: isCart
      ? product.size || `${product.bandSize}-${product.cupSize}`
      : isEresaIntent
        ? product.size
        : undefined,
    category1: isCart ? undefined : (product.categories?.lvl0 ?? undefined),
    category2: isCart ? undefined : (product.categories?.lvl1 ?? undefined),
    category3: isCart ? undefined : (product.categories?.lvl2 ?? undefined),
    currentprice_ati: isCart ? product.rowTotal / product.quantity : product.storePrice,
    originalprice_ati: isCart ? product.rowBaseTotal / product.quantity : product.originalPrice,
    discount_ati: isCart
      ? (product.rowBaseTotal - product.rowTotal) / product.quantity
      : product.originalPrice - product.storePrice,
    currentprice_tf: isCart
      ? getTaxFree({
          amount: product.rowTotal / product.quantity,
          taxRate: product.tax,
        })
      : getTaxFree({ amount: product.storePrice, taxRate: product.tax }),
    originalprice_tf: isCart
      ? getTaxFree({
          amount: product.rowBaseTotal / product.quantity,
          taxRate: product.tax,
        })
      : getTaxFree({
          amount: product.originalPrice,
          taxRate: product.tax,
        }),
    discount_tf: isCart
      ? getTaxFree({
          amount: (product.rowBaseTotal - product.rowTotal) / product.quantity,
          taxRate: product.tax,
        })
      : getTaxFree({
          amount: product.originalPrice - product.storePrice,
          taxRate: product.tax,
        }),
  };
}

function getFilters(refinementList, range) {
  const filters = {
    size: refinementList?.['sizeVariants.sizeFilter']?.length
      ? refinementList['sizeVariants.sizeFilter']
      : undefined,
    colorLabel: refinementList?.colorLabel?.length ? refinementList.colorLabel : undefined,
    typology: refinementList?.typology?.length ? refinementList.typology : undefined,
    collectionName: refinementList?.collectionName?.length
      ? refinementList.collectionName
      : undefined,
    promotionPercentage: refinementList?.promotionPercentage?.length
      ? refinementList.promotionPercentage
      : undefined,
    minPrice: range?.storePrice?.[0],
    maxPrice: range?.storePrice?.[1],
  };

  return Object.values(filters).some((x) => x) ? filters : undefined;
}

const MS_IN_A_YEAR = 31536000000;

export function calculateYearDiff(d1: Date, d2: Date): number {
  return Math.floor(Math.abs(d2.getTime() - d1.getTime()) / MS_IN_A_YEAR);
}

export function getIsNewCustomer(lastOrderDate: string | undefined): CustomerStatus {
  return !lastOrderDate || calculateYearDiff(new Date(lastOrderDate), new Date()) > 365
    ? CustomerStatus.NEW_CUSTOMER
    : CustomerStatus.NOT_NEW_CUSTOMER;
}

export function getCart(cartDetail: CartDetail): Cart {
  const items = cartDetail.items ?? [];
  const productsCount = items.reduce((accumulator, item) => accumulator + item.quantity, 0);
  const cartProducts = getCatalog({ products: items, isCart: true });
  return {
    id: cartDetail.cartId,
    amount: cartDetail.total,
    promo_code: cartDetail.couponCode,
    promo_code_discount: cartDetail.totalCouponAmount,
    products_count: productsCount,
    products: cartProducts,
  };
}

export function createDataLayer({
  pathname,
  search,
  cartDetail,
  cartStep,
  isLoggedIn,
  userInfo,
  userDetail,
  product,
  catalog,
}: InitParams): DataLayer {
  const env_work = process.env.DEPLOYMENT_ENV === 'production' ? 'prod' : 'preprod';
  const env_device = getDevice();

  let page_id,
    page_name,
    page_template,
    page_error,
    page_category1,
    page_category2,
    page_category3,
    page_products,
    page_filters;

  const [path = '', category1 = '', category2 = '', category3 = ''] = pathname
    .split('/')
    .filter(Boolean);

  page_id = page_name = `${path}${category1 ? `-${category1}` : ''}${
    category2 ? `-${category2}` : ''
  }${category3 ? `-${category3}` : ''}`;

  const { query, page, trierPar } = qs.parse(search.slice(1)) ?? {};

  const { refinementList, range } = urlToSearchState(pathname, search);

  const page_query = query ?? undefined;
  const page_number = page || '1';
  const page_sorting = trierPar;
  const page_size = '12';

  switch (`/${path}`) {
    case paths.ACCOUNT: {
      page_template = category1 === accountPaths.INFO ? 'myaccount_form' : 'myaccount_other';
      break;
    }
    case paths.CART: {
      page_template =
        cartStep === CartStep.LISTING
          ? 'funnel_cart'
          : cartStep === CartStep.DELIVERY
            ? 'funnel_step1'
            : cartStep === CartStep.PAYMENT
              ? 'funnel_step2'
              : cartStep === CartStep.SUCCESS
                ? 'funnel_confirmation'
                : undefined;
      break;
    }
    case paths.CATALOG:
    case paths.SELECTION: {
      page_template = 'category';
      page_category1 = category1;
      page_category2 = category2;
      page_category3 = category3;
      page_filters = getFilters(refinementList, range);
      page_products = getCatalog({ products: catalog ?? [] });
      break;
    }
    case paths.BRAND:
    case paths.OFFERS:
    case paths.CONTACT: {
      page_template = 'other_generic';
      break;
    }
    case paths.HOME: {
      page_id = 'homepage';
      page_name = 'homepage';
      page_template = 'homepage';
      page_products = getCatalog({ products: catalog ?? [] });
      break;
    }
    case paths.PRODUCT: {
      page_template = 'product';
      page_products = [
        ...getCatalog({ products: product ? [product] : [] }),
        ...getCatalog({ products: catalog ?? [] }),
      ];
      page_category1 = product?.categories?.lvl0 ?? undefined;
      page_category2 = product?.categories?.lvl1 ?? undefined;
      page_category3 = product?.categories?.lvl2 ?? undefined;
      break;
    }
    case paths.LOGOUT: {
      page_template = 'myaccount_other';
      break;
    }
    case paths.RESET_PASSWORD: {
      page_template = 'myaccount_other';
      break;
    }
    case paths.STORE_LOCATOR: {
      page_template = 'other_store';
      break;
    }
    default: {
      page_error = '404';
      page_template = 'other_errors';
    }
  }

  if (paths.GENERIC.includes(`/${path}`)) {
    page_template = 'other_generic';
  }

  const user: User = {};
  user.visitor_id = localStorage.getItem(`CognitoIdentityId-${AWS_IDENTITY_POOL_ID}`) ?? undefined;
  user.flags = getPersistedData('statsig-map-box-flag') ?? [''];
  user.flags = getPersistedData('statsig-home-made-flag') ?? [''];
  if (isLoggedIn) {
    user.last_visit = formatDate(new Date());
    if (userInfo) {
      user.id = userInfo.sub;
      user.email_optin = userInfo.website ? '1' : '0';
    }

    if (userDetail) {
      user.birthdate = userDetail.dob
        ? formatDate(new Date(changeDayMonthOrder(userDetail.dob)))
        : undefined;
      user.age = userDetail.dob ? calculateAge(userDetail.dob) : undefined;
      user.gender =
        userDetail.prefix === 'Monsieur'
          ? 'male'
          : userDetail.prefix === 'Madame'
            ? 'female'
            : undefined;
      user.postalcode = userDetail.addresses?.[0]?.postal ?? undefined;
      user.city = userDetail.addresses?.[0]?.city ?? undefined;
      user.registration_date = userDetail.createdAt
        ? formatDate(new Date(userDetail.createdAt))
        : undefined;
      user.email = userDetail.email;
      user.email_md5 = userDetail.emailMD5;
      user.email_sh256 = userDetail.emailSHA256;
      user.last_order_date = userDetail.lastOrderDate
        ? formatDate(new Date(userDetail.lastOrderDate))
        : undefined;
      user.is_new_customer = getIsNewCustomer(userDetail.lastOrderDate);
    }
  }

  let cart: Cart | undefined = undefined,
    order: Order | undefined = undefined;

  if (cartDetail) {
    const items = cartDetail.items ?? [];
    const productsCount = items.reduce((accumulator, item) => accumulator + item.quantity, 0);
    const rowBaseTotal = items.reduce((accumulator, item) => accumulator + item.rowBaseTotal, 0);
    const cartProducts = getCatalog({ products: items, isCart: true });
    if (cartStep > CartStep.LISTING) {
      order = {
        id: cartDetail.cartId,
        products_count: productsCount,
        products: cartProducts,
        shipping_method:
          cartDetail.shippingType === ShippingTypes.HOME
            ? 'home'
            : cartDetail.shippingType === ShippingTypes.STORE
              ? 'shop'
              : cartDetail.shippingType === ShippingTypes.PICKUP
                ? 'point relais'
                : undefined,
        payment_method: 'credit-card',
        amount_ati_without_sf: cartDetail.total - cartDetail.shippingAmount,
        amount_ati_with_sf: cartDetail.total,
        discount_ati: rowBaseTotal - (cartDetail.total - cartDetail.shippingAmount),
        ship_ati: cartDetail.shippingAmount,
        amount_tf_without_sf: getTaxFree({ amount: cartDetail.total - cartDetail.shippingAmount }),
        amount_tf_with_sf: getTaxFree({ amount: cartDetail.total }),
        tax: getTax({ amount: cartDetail.total }),
        amount_ati_without_sf_without_disc: rowBaseTotal,
        amount_ati_with_sf_without_disc: rowBaseTotal + cartDetail.shippingAmount,
        promo_code: cartDetail.couponCode ?? undefined,
      };
      if (cartStep === CartStep.SUCCESS) {
        order.status = 'validated';
        order.id = cartDetail.orderNumber;
        persistData('orderDetail', {});
      }
    } else {
      cart = getCart(cartDetail);
    }
  }

  return {
    env_work,
    env_device,
    env_language: 'fr',
    env_country: 'fr',
    env_currency: 'EUR',
    page_id,
    page_name,
    page_template,
    page_error,
    page_category1,
    page_category2,
    page_category3,
    page_products,
    page_query,
    page_filters,
    page_number,
    page_size,
    page_sorting,
    user,
    cart,
    order,
  };
}

export function pushToGTM(event: Events, data: object) {
  TagManager.dataLayer({
    dataLayer: {
      event,
      ...data,
    },
  });
}

export function getClientID() {
  try {
    return window._chtlcid.vid;
  } catch (e) {
    return undefined;
  }
}
