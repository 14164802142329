import React from 'react';
import styled from '@emotion/styled';

import { Icon, colors, durations, opacities } from '../../../design-system';
import { Product } from '../types';
import { useDispatch } from 'react-redux';
import { RecommendationEventTypes, trackRecommendation } from '../../recommendations/utils';
import { openModalNew } from '../../common/actions';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { QuickAdd } from '../../quickadd/components/QuickAdd';
import { getProductImage } from '../utils';
import { HighlightedName } from '../../catalog/components/HighlightedName';
import { Price } from '../../catalog/components/Price';
import { PromotionLabel } from '../../catalog/components/PromotionLabel';
import { getTrackingProduct, pushToGTM } from '../../tracking';
import { Events } from '../../tracking/types';
import { isEcoLabel } from '../../catalog/utils';

type Props = {
  hit: Product;
  trackingId?: string;
  setShowMobileQuickAdd: (value: boolean) => void;
  setClickedItem: (value: string) => void;
};

const CROSS_SELL_PRODUCT_IMAGE_WIDTH = 104;

const StyledProductButton = styled.button<{ isEcoLabel: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 8px;
  background-color: ${colors.BACKGROUND};
  min-height: 172px;
  width: 100%;
  padding: 16px;
  cursor: pointer;
  transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

  @media (hover: hover) {
    &:hover {
      opacity: ${opacities.HOVERED};
    }
  }

  .cross-sell-product-image {
    height: 100%;
    width: ${CROSS_SELL_PRODUCT_IMAGE_WIDTH}px;
    margin-right: 16px;
    border-radius: 4px;
  }

  .cross-sell-product-info-block {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
  }

  .cross-sell-product-label-block {
    display: flex;
    width: calc(100% - 40px); // avoid issue with fake absolute button
    flex-wrap: wrap;
    gap: 8px;
  }

  .cross-sell-fake-button-add-to-cart {
    position: absolute;
    border: solid 1px ${colors.LIGHT};
    bottom: 16px;
    right: 16px;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.WHITE};
  }

  .product-rank-label,
  .product-attribute-label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 4px;
    height: 20px;
  }

  .product-rank-label {
    color: ${colors.WHITE};
    background-color: ${colors.BLACK};
    gap: 4px;
  }

  .product-attribute-label {
    color: ${(props) => (props.isEcoLabel ? colors.WHITE : colors.MAIN_GREY)};
    background-color: ${(props) => (props.isEcoLabel ? colors.GREEN_LABEL : colors.LIGHT2)};
  }

  .icon-label {
    margin-top: -2px;
  }
`;

export const CrossSellProductPageItem = ({
  hit,
  trackingId,
  setShowMobileQuickAdd,
  setClickedItem,
}: Props) => {
  const dispatch = useDispatch();
  const { isMobile } = useMediaQueries();

  const {
    productRef,
    colorRef,
    productName,
    collectionName,
    colorLabel,
    originalPrice,
    storePrice,
    promotionPercentage,
    promotionLabel,
    promoLongLabel,
    promoBColor,
    promoTColor,
    isOutlet,
    label1: rankLabel,
    label2: attributeLabel,
  } = hit;

  const objectID = `${hit.productRef}-${hit.colorRef}`;
  const showLabel =
    promotionLabel || promoLongLabel || promotionPercentage || rankLabel || attributeLabel;

  const onItemClick = () => {
    const product = getTrackingProduct({ product: hit });
    pushToGTM(Events.clickedRecommended, {
      product,
      type: RecommendationEventTypes.crossSell,
    });

    pushToGTM(Events.quickAddOpen, {
      product,
    });

    trackRecommendation(trackingId);

    if (isMobile) {
      setClickedItem(`${productRef}-${colorRef}`);
      setShowMobileQuickAdd(true);
    } else {
      dispatch(
        openModalNew({
          content: <QuickAdd productRef={productRef} colorRef={colorRef} />,
          preset: 'medium',
        })
      );
    }
  };

  return (
    <StyledProductButton
      type="button"
      data-testid={`cross-sell-product-card-${objectID}-pdp-button`}
      id={`cross-sell-product-card-${objectID}-pdp-button`}
      isEcoLabel={isEcoLabel(attributeLabel)}
      onClick={onItemClick}
    >
      <img
        className="cross-sell-product-image"
        src={
          getProductImage({
            productRef,
            productName,
            colorRef,
            colorLabel,
            position: 1,
            width: CROSS_SELL_PRODUCT_IMAGE_WIDTH,
          })?.jpg
        }
        alt={`image du produit ${productName}`}
      />
      <div className="cross-sell-product-info-block">
        <HighlightedName
          objectID={objectID}
          productName={productName}
          collectionName={collectionName}
        />
        <Price originalPrice={originalPrice} storePrice={storePrice} />
        {showLabel && (
          <div className="cross-sell-product-label-block">
            <PromotionLabel
              promotionPercentage={promotionPercentage}
              promotionLabel={promoLongLabel ?? promotionLabel}
              promotionBackgroundColor={promoBColor}
              promotionTextColor={promoTColor}
              objectID={objectID}
              isOutlet={isOutlet}
            />
            {rankLabel && (
              <span className="product-rank-label">
                {rankLabel.toLocaleLowerCase() === 'bestseller' && (
                  <Icon className="icon-label" name="star" size={12} color={colors.WHITE} />
                )}
                {rankLabel}
              </span>
            )}
            {attributeLabel && (
              <span className="product-attribute-label">
                {isEcoLabel(attributeLabel) && (
                  <Icon className="icon-label" name="leaf" size={16} />
                )}
                {attributeLabel}
              </span>
            )}
          </div>
        )}
      </div>
      <div className="cross-sell-fake-button-add-to-cart">
        <Icon name="eyeOn" size={20} />
      </div>
    </StyledProductButton>
  );
};
