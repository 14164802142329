import {
  CmsAnimatedPictures,
  CmsBrandBanner,
  CmsCategorieBanner,
  CmsFullSlider,
  CmsFullTitle,
  CmsGalleryBanner,
  CmsOurBrand,
  CmsOurBrandPosition,
  CmsPicturesSlider,
  CmsTabsCategorieBanner,
  CmsTagLine,
} from '../cms/types';

export const ANIMATED_BANNER_MAX_WIDTH = 1440;
export const ANIMATED_BANNER_IMAGE_HEIGHT = 400;
export const PICTURES_SLIDER_IMAGE_HEIGHT = 420;
export const PICTURES_SLIDER_IMAGE_WIDTH = 287.5;
export const PICTURES_SLIDER_TRACK_GAP = 24;
export const TABS_CATEGORIE_TILE_WIDTH = 328;
export const TABS_CATEGORIE_TILE_HEIGHT = 564;
export const TABS_CATEGORIE_HEADER_TABS_WIDTH = 286;
export const FULL_SLIDER_MEDIA_HEIGHT = 489;
export const FULL_SLIDER_MEDIA_WIDTH = 815;

type CmsSliceType =
  | 'brand_banner'
  | 'full_title'
  | 'tag_line'
  | 'gallery_banner'
  | 'animated_picture'
  | 'pictures_slider'
  | 'categorie_banner'
  | 'tabs_categorie'
  | 'full_slider';

type CmsSlice =
  | CmsBrandBanner
  | CmsFullTitle
  | CmsTagLine
  | CmsGalleryBanner
  | CmsAnimatedPictures
  | CmsPicturesSlider
  | CmsCategorieBanner
  | CmsTabsCategorieBanner
  | CmsFullSlider;

export const getSliceBody = (cmsData: CmsOurBrand, sliceType: CmsSliceType): CmsSlice[] | null => {
  for (const key in cmsData) {
    if (key.startsWith('body') && Array.isArray(cmsData[key])) {
      const bodyArray = cmsData[key] as CmsSlice[];
      if (bodyArray.some((item) => item.slice_type === sliceType)) {
        return bodyArray;
      }
    }
  }

  return null;
};

export const getOurBrandBannerPosition = (position: CmsOurBrandPosition): number => {
  return position === 'Not activated' ? 0 : parseInt(position, 10);
};
