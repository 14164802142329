import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { breakpoints, colors } from '../../../design-system';
import { setStep } from '../../cart/actions';
import { CartStep } from '../../cart/types';
import { closeModal } from '../../common/actions';
import PaymentToolTip from './PaymentToolTip';

type Props = { message: string; customerServiceMail: boolean; toolTip: boolean };

const StyledContainer = styled.div`
  padding: 32px;
  line-height: 2rem;

  @media (min-width: ${breakpoints.S}px) {
    line-height: 110%;
  }

  .payment-error-btn {
    padding: 0;
    background-color: unset;
    border: none;
    color: ${colors.ERROR};
    font-size: 1.4rem;

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .payment-error-text {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 1.4rem;
    color: ${colors.ERROR};
    font-weight: 700;
  }

  .payment-error-link {
    color: ${colors.ERROR};
  }

  .payment-error-text-second {
    font-weight: 700;
    a {
      color: ${colors.ERROR};
      padding-bottom: 1px;
    }
  }
`;

export const ErrorsModal = ({ message, customerServiceMail, toolTip }: Props) => {
  const dispatch = useDispatch();

  const onBtnClick = () => {
    dispatch(closeModal());
    dispatch(setStep(CartStep.DELIVERY));
  };

  return (
    <StyledContainer>
      <p id="err-form-payment" className="payment-error-text">
        {message}
        <br />
        <br />
        {'Veuillez recommencer en '}
        <button
          className="payment-error-btn"
          id="btn-payment-redirect-delivery"
          data-testid="btn-payment-redirect-delivery"
          type="button"
          onClick={onBtnClick}
        >
          <span className="anchor-animated">{'cliquant ici'}</span>
        </button>
        {customerServiceMail && (
          <span className="payment-error-text-second">
            {' ou '}
            <a
              className="anchor-animated"
              id="anchor-payment-open-contact-email"
              data-testid="anchor-payment-open-contact-email"
              href="https://faq.darjeeling.fr/hc/fr/requests/new"
              rel="noreferrer"
            >
              <span className="payment-error-link">{'contacter notre service client'}</span>
            </a>
            {' .'}
          </span>
        )}
      </p>
      {toolTip && <PaymentToolTip />}
    </StyledContainer>
  );
};
