import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import styled from '@emotion/styled';

import { RootState } from 'src/shared/store/rootReducer';
import { useProductsInfos } from '../../common/hooks/useProductsInfos';
import { CrossSellProductPageItem } from './CrossSellProductPageItem';
import { QuickAddMobile } from '../../quickadd/components/QuickAddMobile';
import { cmsProductInit } from '../../cms/actions';
import { setCrossSellLoading } from '../actions';

const StyledCrossSellContainer = styled.div`
  overflow: hidden;
  width: 100%;

  .cross-sell-header-block {
    margin-bottom: 16px;
  }

  .cross-sell-title {
    font-size: 2rem;
    line-height: 2.4rem;
    margin: 0;
    font-weight: 700;
    font-family: 'ogg-text';
    flex-grow: 1;
    text-align: left;
  }

  .cross-sell-items {
    display: flex;
    flex-direction: column;
  }

  .cross-sell-item:not(:first-of-type) {
    margin-top: 8px;
  }

  .cross-sell-item:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

export const CrossSellProductPage = () => {
  const dispatch = useDispatch();
  const { recommendedProducts, tracking } = useSelector(
    (state: RootState) => state.recommendations.blocks.crossSell
  );
  const { cross_sell_title } = useSelector((state: RootState) => state.cms.product);
  const { isFetching } = useSelector((state: RootState) => state.recommendations);
  const { showFeedback } = useSelector((state: RootState) => state.cart);
  const { hits } = useProductsInfos(recommendedProducts);
  const [showMobileQuickAdd, setShowMobileQuickAdd] = useState(false);
  const [clickedItem, setClickedItem] = useState('');
  const [productRef, colorRef] = clickedItem.split('-') ?? '';

  const onClose = () => {
    setClickedItem('');
    setShowMobileQuickAdd(false);
  };

  useEffect(() => {
    if (!cross_sell_title) {
      dispatch(cmsProductInit() as unknown as UnknownAction);
    }
  }, []);

  useEffect(() => {
    if (
      (!isFetching &&
        recommendedProducts.length > 0 &&
        hits.length === recommendedProducts.length) ||
      (!isFetching && recommendedProducts.length === 0)
    ) {
      dispatch(setCrossSellLoading(false));
    }
  }, [recommendedProducts, hits, isFetching]);

  useEffect(() => {
    if (showFeedback) {
      setClickedItem('');
      setShowMobileQuickAdd(false);
    }
  }, [showFeedback]);

  return !hits.length ? null : (
    <>
      <StyledCrossSellContainer>
        <div className="cross-sell-header-block">
          {cross_sell_title && <h2 className="cross-sell-title">{cross_sell_title}</h2>}
        </div>
        <div className="cross-sell-items">
          {hits.map((hit) => {
            const { productRef, colorRef } = hit;
            const trackingId = (tracking ?? []).find(
              (item) => item.id === `${hit.productRef}-${hit.colorRef}`
            )?.trackingId;
            return (
              <div className="cross-sell-item" key={`${productRef}—${colorRef}`}>
                <CrossSellProductPageItem
                  hit={hit}
                  trackingId={trackingId}
                  setShowMobileQuickAdd={setShowMobileQuickAdd}
                  setClickedItem={setClickedItem}
                />
              </div>
            );
          })}
        </div>
      </StyledCrossSellContainer>
      <QuickAddMobile
        productRef={productRef}
        colorRef={colorRef}
        originalSelectedColor={colorRef}
        isActive={showMobileQuickAdd}
        onClose={onClose}
      />
    </>
  );
};
