import { getIsClient } from '../common/utils';
import { TARGET2SELL_URL } from '../api/constants';
import { RecoBlockIdTypes } from './types';

declare global {
  interface Window {
    T2S?: {
      click?: ({ trackingId }: { trackingId: string }) => void;
    };
  }
}

export const getCookie = (name: string): string | undefined => {
  if (!getIsClient()) {
    return undefined;
  }
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[]\\\/+^])/g, '\\$1')}=([^;]*)`)
  );

  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (
  name: string,
  value: string,
  options: { expires: string | number } = { expires: 7 * 24 * 60 * 60 } // 7 days as a fallback
): void => {
  try {
    const optionsObj = {
      ...{ path: '/' },
      ...options,
    };

    if (typeof optionsObj.expires === 'number') {
      const d = new Date();
      d.setTime(d.getTime() + optionsObj.expires * 1000);

      optionsObj.expires = d.toUTCString();
    }

    const newCookie = `${name}=${encodeURIComponent(value)}`;

    const newCookieOptions = Object.entries(optionsObj).reduce(
      (accumulator, [key, newCookieOption]) => `${accumulator}${key}=${newCookieOption};`,
      '; '
    );

    document.cookie = newCookie + newCookieOptions;
  } catch (error) {
    //  console.error(`ERROR setCookie helper: ${error}`);
  }
};

export const deleteCookie = (name: string): void => {
  setCookie(name, '', { expires: -1 });
};

export function trackRecommendation(trackingId: string | undefined) {
  if (!trackingId || typeof window.T2S?.click !== 'function') {
    return;
  }

  window.T2S.click({ trackingId });
}

export async function postToT2S(data = {}) {
  const response = await fetch(TARGET2SELL_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  const json = await response.json();
  return json;
}

export function getFilter(items) {
  return {
    filter:
      items
        .map((item) => item.externalID.replace('/', '-'))
        .reduce((accumulator, item) => `${accumulator} OR rcFilter:"${item}"`, '')
        ?.substring(4) ?? '',
    tracking: items.map((item) => ({
      id: item.externalID.replace('/', '-'),
      trackingId: item.trackingId,
    })),
    recommendedProducts: items.map((item) => item.externalID.replace('/', '-')),
  };
}

export function reshapeHomeMadeRecommendationsData(items) {
  return {
    filter: items.map((item) => `rcFilter:"${item}"`).join(' OR ') ?? '',
    recommendedProducts: items,
  };
}

export enum RecommendationEventTypes {
  upSell = 'up-sell',
  upSellTabs = 'up-sell-tabs',
  crossSell = 'cross-sell',
  crossSellFeedback = 'cross-sell-feedback',
}

export const getRecommendationsType = (id: RecoBlockIdTypes): RecommendationEventTypes => {
  if (id === 'home-page-tabs') {
    return RecommendationEventTypes.upSellTabs;
  }
  if (id === 'home-page' || id === 'cart-page' || id === 'product-page') {
    return RecommendationEventTypes.upSell;
  }

  return RecommendationEventTypes.crossSellFeedback;
};
