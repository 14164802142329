import { action, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';

import types from './actionTypes';
import {
  RecommendationPageIDs,
  LoadProductRecommendationsParams,
  RecommendationBlocksIDs,
  RecommendationTypes,
} from './types';
import { TARGET2SELL_CUSTOMER_ID } from '../api/constants';
import { getCookie, postToT2S, getFilter, reshapeHomeMadeRecommendationsData } from './utils';
import { RootState } from '../../store/rootReducer';

export const requestRecommendations = () => action(types.REQUEST_RECOMMENDATIONS_LOADING, null);
export const requestRecommendationsSuccess = (payload) =>
  action(types.REQUEST_RECOMMENDATIONS_SUCCESS, payload);
export const requestRecommendationsFailure = (payload: string) =>
  action(types.REQUEST_RECOMMENDATIONS_FAILURE, payload);

type AsyncRecommendationsActions = ActionType<
  | typeof requestRecommendations
  | typeof requestRecommendationsSuccess
  | typeof requestRecommendationsFailure
>;

export type RecommendationsActions = AsyncRecommendationsActions;

export function loadProductRecommendations({
  productRef,
  colorRef,
}: LoadProductRecommendationsParams) {
  return async (dispatch: Dispatch<RecommendationsActions>, getState: () => RootState) => {
    dispatch(requestRecommendations());
    const state = getState();
    const email = state.account.user?.emailSHA256;

    try {
      const response = await postToT2S({
        cID: TARGET2SELL_CUSTOMER_ID,
        pID: RecommendationPageIDs.product,
        tID: getCookie('t2s-analytics') || 'UNKNOWN',
        uEM: getCookie('SSGTMEM') || email || undefined,
        uID: getCookie('SSGTMUID'),
        iID: productRef && colorRef ? `${productRef}/${colorRef}` : undefined,
        cURL: window.location.href,
      });

      if ((response.blocks ?? []).length > 0) {
        const crossSellItems =
          response.blocks.find((block) => block?.id?.endsWith(RecommendationBlocksIDs.crossSell))
            ?.items ?? [];
        const upSellItems =
          response.blocks.find((block) => block?.id?.endsWith(RecommendationBlocksIDs.upSell))
            ?.items ?? [];
        dispatch(
          requestRecommendationsSuccess({
            crossSell: getFilter(crossSellItems),
            upSell: getFilter(upSellItems),
          })
        );
      } else {
        dispatch(requestRecommendationsFailure(response.message));
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch(requestRecommendationsFailure(error.message));
      }
    }
  };
}

export const loadProductRecommendationsHomemade = ({
  productRef,
  colorRef,
}: LoadProductRecommendationsParams) => {
  return async (dispatch: Dispatch<RecommendationsActions>) => {
    const product = `${productRef}-${colorRef}`;
    const url = `https://europe-west2-web-tagmanager-dev.cloudfunctions.net/getRecommendations?id=${product}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const crossSellItems = data.crossSellRecommendations ?? [];
      const upSellItems = data.upSellRecommendations ?? [];

      if ((crossSellItems ?? []).length > 0 || (upSellItems ?? []).length > 0) {
        dispatch(
          requestRecommendationsSuccess({
            crossSell: reshapeHomeMadeRecommendationsData(crossSellItems),
            upSell: reshapeHomeMadeRecommendationsData(upSellItems),
          })
        );
      } else {
        dispatch(requestRecommendationsFailure(data.message));
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch(requestRecommendationsFailure(error.message));
      }
    }
  };
};

export function loadRecommendations(page: RecommendationTypes, ids?: (string | undefined)[]) {
  return async (dispatch: Dispatch<RecommendationsActions>, getState: () => RootState) => {
    dispatch(requestRecommendations());

    const state = getState();
    const email = state.account.user?.emailSHA256;

    try {
      const response = await postToT2S({
        cID: TARGET2SELL_CUSTOMER_ID,
        pID: RecommendationPageIDs[page],
        tID: getCookie('t2s-analytics') || 'UNKNOWN',
        uEM: getCookie('SSGTMEM') || email || undefined,
        uID: getCookie('SSGTMUID'),
        cURL: window.location.href,
      });

      if ((response.blocks ?? []).length > 0) {
        const items =
          response.blocks.find((block) =>
            block?.id?.endsWith(`${RecommendationPageIDs[page]}-${RecommendationBlocksIDs[page]}`)
          )?.items ?? [];

        dispatch(
          requestRecommendationsSuccess({
            [page]: getFilter(items),
          })
        );

        if (ids) {
          ids.forEach((id) => {
            if (!id) {
              return;
            }
            const items =
              response.blocks.find((block) => block?.id?.endsWith(`${id}`))?.items ?? [];

            dispatch(
              requestRecommendationsSuccess({
                [`homeTab${id}`]: getFilter(items),
              })
            );
          });
        }
      } else {
        dispatch(requestRecommendationsFailure(response.message));
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch(requestRecommendationsFailure(error.message));
      }
    }
  };
}
