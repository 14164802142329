import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';

import { breakpoints, colors } from '../../../design-system';
import { CmsFaqCart, CmsFaqItem } from '../../cms/types';
import { CartFaqItem } from './CartFaqItems';

type Props = {
  cmsContent: CmsFaqCart[];
};

export const CartFaq = ({ cmsContent }: Props) => {
  const cmsFaq = Array.isArray(cmsContent)
    ? cmsContent.find((slice: CmsFaqCart) => slice.slice_type === 'faq_section')
    : null;
  if (!cmsFaq) {
    return null;
  }

  const [questionBlockStates, setQuestionBlockStates] = React.useState<Array<boolean>>([]);
  const questionBlockRefs = React.useRef<Array<React.RefObject<HTMLDivElement>>>([]);
  const {
    items,
    primary: {
      cart_faq_title,
      cart_faq_title_color,
      cart_faq_link_text,
      cart_faq_link_color,
      cart_faq_background_color,
      cart_faq_answer_color,
      cart_faq_question_color,
      cart_faq_divider_color,
    },
  } = cmsFaq;

  return (
    <div
      id="cart-page-faq-block"
      css={css`
        background: ${cart_faq_background_color ?? colors.WHITE};
        display: flex;
        flex-direction: column;
        padding: 32px 16px;
        @media (min-width: ${breakpoints.M}px) {
          margin: 16px 0 16px 16px;
        }
        @media (min-width: ${breakpoints.L}px) {
          margin-left: 72px;
        }
      `}
    >
      {cart_faq_title && (
        <h2
          id="cart-page-faq-title"
          css={css`
            font-size: 1.6rem;
            line-height: 110%;
            font-weight: 700;
            margin-bottom: 24px;
            color: ${cart_faq_title_color ?? colors.BLACK};
            @media (min-width: ${breakpoints.S}px) {
              font-size: 2rem;
            }
          `}
        >
          {cart_faq_title}
        </h2>
      )}
      {items?.map((item: CmsFaqItem, index: number) => {
        return !item.cart_faq_question || !item.cart_faq_answer ? null : (
          <CartFaqItem
            key={`cart-faq-questions-answer-block-${index}`}
            item={item}
            index={index}
            questionBlockRefs={questionBlockRefs}
            questionBlockStates={questionBlockStates}
            setQuestionBlockStates={setQuestionBlockStates}
            cart_faq_question_color={cart_faq_question_color}
            cart_faq_divider_color={cart_faq_divider_color}
            cart_faq_answer_color={cart_faq_answer_color}
          />
        );
      })}
      {cart_faq_link_text && (
        <div
          css={css`
            margin-top: 24px;
            width: max-content;
          `}
        >
          <Link
            className="anchor-animated"
            data-testid="cart-page-faq-link"
            id="cart-page-faq-link"
            to="https://faq.darjeeling.fr/hc/fr"
            css={css`
              color: ${cart_faq_link_color ?? colors.BLACK};
              font-size: 1.4rem;
            `}
          >
            {cart_faq_link_text}
          </Link>
        </div>
      )}
    </div>
  );
};
