import React from 'react';
import styled from '@emotion/styled';

import { Icon, breakpoints, durations, opacities } from '../../../design-system';
import { getImageList } from '../../product/utils';
import { PromotionLabel } from '../../catalog/components/PromotionLabel';
import { ModalImage } from './ModalImage';

const QUICK_ADD_MOBILE_IMAGE_WIDTH = 148;

type Props = {
  promotionLabel?: string;
  promotionPercentage?: string;
  promotionBackgroundColor?: string;
  promotionTextColor?: string;
  selectedImagePosition: number;
  setSelectedImagePosition: (value: number) => void;
  isOutlet?: boolean;
  objectID: string;
  images: number[];
  productRef: string;
  productName: string;
  colorLabel: string;
  colorRef: string;
  rankLabel?: string;
};

const StyledModalImagesContainer = styled.div<{
  isLeftArrowActive: boolean;
  isRightArrowActive: boolean;
}>`
  overflow-x: auto;

  .modal-images-block-mobile {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: ${QUICK_ADD_MOBILE_IMAGE_WIDTH}px;
    grid-gap: 8px;

    @media (min-width: ${breakpoints.S}px) {
      display: none;
    }
  }

  .modal-images-block-desktop {
    display: none;

    @media (min-width: ${breakpoints.S}px) {
      display: block;
    }

    .modal-images-label-wrapper {
      position: relative;
    }

    .modal-image-navigation-block {
      margin: 8px 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .modal-image-arrow-left-button,
    .modal-image-arrow-right-button {
      border: none;
      background-color: unset;
      padding: 0;
      transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;
      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          opacity: ${opacities.HOVERED};
        }
      }
    }

    .modal-image-arrow-left-button {
      pointer-events: ${(props) => (props.isLeftArrowActive ? 'initial' : 'none')};
      opacity: ${(props) => (props.isLeftArrowActive ? 1 : opacities.DISABLED)};
    }

    .modal-image-arrow-right-button {
      pointer-events: ${(props) => (props.isRightArrowActive ? 'initial' : 'none')};
      opacity: ${(props) => (props.isRightArrowActive ? 1 : opacities.DISABLED)};
    }

    .product-modal-image-position-text {
      font-size: 1.4rem;
      margin: 0;
    }
  }
`;

export const ModalImages = ({
  promotionLabel,
  promotionPercentage,
  promotionBackgroundColor,
  promotionTextColor,
  selectedImagePosition,
  setSelectedImagePosition,
  isOutlet,
  objectID,
  images,
  productRef,
  productName,
  colorLabel,
  colorRef,
  rankLabel,
}: Props) => {
  const imageList = getImageList({
    imagePositions: images ?? [],
    productRef,
    colorRef,
    productName,
    colorLabel,
  });
  const scaledImageList = imageList.map((image) => image.default);
  const canGoBack = selectedImagePosition > 0;
  const canGoForward = selectedImagePosition < scaledImageList.length - 1;

  return (
    <StyledModalImagesContainer isLeftArrowActive={canGoBack} isRightArrowActive={canGoForward}>
      <div className="modal-images-block-mobile">
        {scaledImageList.map((image, index) => (
          <ModalImage
            key={`modal-images-mobile-product-${productRef}-${colorRef}-${index}`}
            image={image}
            objectID={objectID}
          />
        ))}
      </div>
      <div className="modal-images-block-desktop">
        <div className="modal-images-label-wrapper">
          <PromotionLabel
            promotionLabel={promotionLabel}
            promotionPercentage={promotionPercentage}
            promotionBackgroundColor={promotionBackgroundColor}
            promotionTextColor={promotionTextColor}
            objectID={objectID}
            isOutlet={isOutlet}
            rankLabel={rankLabel}
            isPositionAbsolute
          />
          <ModalImage image={scaledImageList[selectedImagePosition]} objectID={objectID} />
        </div>
        <div className="modal-image-navigation-block">
          <button
            className="modal-image-arrow-left-button"
            data-testid="modal-image-arrow-left-button"
            type="button"
            onClick={() => setSelectedImagePosition(selectedImagePosition - 1)}
            disabled={!canGoBack}
          >
            <Icon name="arrowLeft" size={16} />
          </button>
          <p className={`product-modal-image-position-text`}>{`${selectedImagePosition + 1} sur ${
            (images ?? [1]).length
          }`}</p>
          <button
            className="modal-image-arrow-right-button"
            data-testid="modal-image-arrow-right-button"
            type="button"
            onClick={() => setSelectedImagePosition(selectedImagePosition + 1)}
            disabled={!canGoForward}
          >
            <Icon name="arrowRight" size={16} />
          </button>
        </div>
      </div>
    </StyledModalImagesContainer>
  );
};
