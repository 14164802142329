import * as React from 'react';

import { Divider, Box } from '../../../design-system';
import { StoreAvailable } from '../../product/types';
import { Store as StoreType, Position, StoresListType } from '../types';
import { sortByDistance } from '../utils';
import { StoreInfo } from './StoreInfo';

type Props = {
  stores: StoreType[];
  selectedStoreId?: string;
  selectedMarker: string;
  storeRefs: object;
  storeslistType?: StoresListType;
  currentPosition?: Position;
  currentStore?: string;
  storesAvailable?: StoreAvailable[];
  onClick: (id: string) => void;
  onMapLinkClick: () => void;
  onSelect?: (id: string) => void;
  highlightMarker?: (id: string) => void;
  isEresaModal?: boolean;
  clickedStoreIsLoading?: string;
  isDelivery?: boolean;
  searchText: string;
};

export const StoreList = ({
  stores = [],
  currentStore = '',
  storesAvailable = [],
  selectedMarker,
  selectedStoreId,
  storeRefs,
  storeslistType,
  currentPosition,
  onClick,
  onMapLinkClick,
  onSelect,
  highlightMarker,
  isEresaModal,
  clickedStoreIsLoading,
  isDelivery,
  searchText,
}: Props) => {
  const [sortedStores, setSortedStores] = React.useState(stores);

  React.useEffect(() => {
    if (currentPosition && searchText) {
      setSortedStores(sortByDistance({ listToOrder: stores, currentPosition }) as StoreType[]);
    } else {
      setSortedStores(
        stores.sort((a, b) => {
          if (!a.address?.postalCode || !b.address?.postalCode) {
            return 0;
          }
          return parseInt(a.address?.postalCode, 10) < parseInt(b.address?.postalCode, 10) ? -1 : 1;
        })
      );
    }
  }, [currentPosition, stores, searchText]);

  return (
    <>
      {sortedStores?.map((store, index) => (
        <Box
          key={store.id}
          ref={storeRefs[index]}
          border={isEresaModal ? 'none' : store.id === selectedMarker ? '1px solid' : ''}
        >
          <StoreInfo
            isSelected={
              store.id === selectedStoreId ||
              (storesAvailable.length > 0 && store.id === currentStore)
            }
            storeAvailable={storesAvailable.find((item) => item.store === store.id)}
            showStockAvailability={storesAvailable.length > 0}
            isMarked={store.id === selectedMarker}
            isLoading={clickedStoreIsLoading === store.id ? true : false}
            index={index}
            storeRefs={storeRefs}
            store={store}
            storeslistType={storeslistType}
            currentPosition={currentPosition}
            onClick={onClick}
            onMapLinkClick={onMapLinkClick}
            onSelect={onSelect}
            highlightMarker={highlightMarker}
            isEresaModal={isEresaModal}
            isDelivery={isDelivery}
          />
          <Divider my="0" />
        </Box>
      ))}
    </>
  );
};
