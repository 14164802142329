import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { colors } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { RankAttributeLabel } from './RankAttributeLabel';

type Props = {
  objectID: string;
  promotionLongLabel?: string;
  promotionTextColor?: string;
  promotionBackgroundColor?: string;
  rankLabel?: string;
  attributeLabel?: string;
};

type StyledPromotionLabelContainerProps = {
  promotionLongLabelBgColor: string;
  promotionLongLabelTextcolor: string;
};

const StyledPromotionLabelContainer = styled.div<StyledPromotionLabelContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  width: 100%;

  .promotion-long-label {
    display: flex;
    height: 32px;
    justify-content: center;
    align-items: center;
    padding: 8px;
    font-weight: 700;
    font-size: 1.4rem;
    border-radius: 4px;
    color: ${(props) => props.promotionLongLabelTextcolor};
    background-color: ${(props) => props.promotionLongLabelBgColor};
  }
`;

export const PromotionLabel = ({
  promotionLongLabel,
  promotionTextColor,
  promotionBackgroundColor,
  rankLabel,
  attributeLabel,
  objectID,
}: Props) => {
  const { label_text_color: defaultTextColor, label_background_color: defaultBackgroundColor } =
    useSelector((state: RootState) => state.cms.promotion) ?? {};

  if (!(promotionLongLabel || rankLabel || attributeLabel)) {
    return null;
  }

  return (
    <StyledPromotionLabelContainer
      promotionLongLabelBgColor={promotionBackgroundColor || defaultBackgroundColor || 'unset'}
      promotionLongLabelTextcolor={promotionTextColor || defaultTextColor || colors.BLACK}
    >
      <RankAttributeLabel rankLabel={rankLabel} attributeLabel={attributeLabel} />
      {promotionLongLabel && (
        <span className="promotion-long-label" id={`pdp-item-promotion-label-${objectID}`}>
          {promotionLongLabel}
        </span>
      )}
    </StyledPromotionLabelContainer>
  );
};
