import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { Logo } from '../../common/components/Logo';
import { Menu } from '../../menu';
import { zIndex } from '../../common/constants';
import { Icon, breakpoints, colors } from '../../../design-system';
import ScrollBackToTopButton from './ScrollBackToTopButton';
import paths from '../../routing/paths';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../shared/store/rootReducer';
import { HeaderRightSide } from '..';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

const HeaderContainer = styled.header`
  --svg-stroke-color: ${colors.WHITE};
  --svg-fill-color: transparent;
  position: sticky;
  top: 0;
  z-index: ${zIndex.HEADER};
  padding: 8px;

  @media (min-width: ${breakpoints.M - 1}px) {
    padding: 16px;
  }
  &.header-container--white-background {
    background: ${colors.WHITE};
    @media (min-width: ${breakpoints.M - 1}px) {
      background: unset;
    }
  }

  .header {
    display: flex;
    position: relative;
    transition: all 0.4s;
    align-items: center;
    justify-content: space-between;
    grid-gap: 36px;
    border-radius: 8px;
    padding: 8px;
    @media (min-width: ${breakpoints.M - 1}px) {
      padding: 0 24px;
    }
    &.header--is-transparent:not(.header--is-activate) {
      &:before {
        content: '';
        position: absolute;
        width: 100vw;
        max-width: 1920px;
        height: calc(100% + 8px);
        left: -8px;
        top: -8px;
        z-index: -1;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
        @media (min-width: ${breakpoints.M - 1}px) {
          height: calc(100% + 16px);
          top: -16px;
          left: -16px;
        }
      }
    }
    &.header--is-activate {
      &:before {
        content: '';
        position: absolute;
        width: 100vw;
        max-width: 1920px;
        height: calc(100% + 8px);
        left: -8px;
        top: -8px;
        z-index: -1;
        backdrop-filter: blur(8px);
        mask: linear-gradient(black 60%, transparent);
        @media (min-width: ${breakpoints.M - 1}px) {
          height: calc(100% + 16px);
          top: -16px;
          left: -16px;
        }
      }
    }
    &.header--is-activate,
    &.header--is-pdp,
    &:not(.header--is-transparent) {
      transition: all 0.4s;
      box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 15%);
      background: ${colors.WHITE};
      .header__logo {
        transition: all 0.4s;
        filter: unset;
      }
      .link-menu:not(.link-menu--third-level) {
        transition: color 0.4s;
        color: ${colors.BLACK};
        opacity: unset;
      }
      .desktop-fake-input__container {
        transition: all 0.4s;
        color: ${colors.BLACK};
        border: 1px solid ${colors.BLACK};
        input {
          transition: all 0.4s;
          color: ${colors.BLACK};
          ::placeholder {
            color: ${colors.BLACK};
          }
        }
        .desktop-input__link {
          color: ${colors.BLACK};
        }
      }
      .input-search__icon,
      .account__icon,
      .shopping-cart__icon,
      .wishlist__icon,
      .search__icon,
      .burger-menu__icon {
        transition: all 0.4s;
        filter: unset;
        --svg-stroke-color: ${colors.BLACK};
      }
    }

    @media (hover: hover) {
      &:hover {
        transition: all 0.4s;
        box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 15%);
        background: ${colors.WHITE};
        .header__logo {
          transition: all 0.4s;
          filter: unset;
        }
        .link-menu:not(.link-menu--third-level) {
          transition: color 0.4s;
          color: ${colors.BLACK};
          opacity: unset;
        }
        .desktop-fake-input__container {
          transition: all 0.4s;
          color: ${colors.BLACK};
          border: 1px solid ${colors.BLACK};
          input {
            transition: all 0.4s;
            color: ${colors.BLACK};
            ::placeholder {
              color: ${colors.BLACK};
            }
          }
          .desktop-input__link {
            color: ${colors.BLACK};
          }
        }
        .input-search__icon,
        .account__icon,
        .shopping-cart__icon,
        .wishlist__icon,
        .search__icon,
        .burger-menu__icon {
          transition: all 0.4s;
          filter: unset;
          --svg-stroke-color: ${colors.BLACK};
        }
      }
    }
  }
  .desktop-header__left-side {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .header__logo,
  .input-search__icon,
  .search__icon,
  .burger-menu__icon,
  .account__icon *,
  .shopping-cart__icon *,
  .wishlist__icon * {
    transition: all 0.4s;
  }
  .header__logo,
  .input-search__icon,
  .search__icon,
  .burger-menu__icon {
    filter: invert(1);
  }

  .header-account__button,
  .header-account__link {
    @media (hover: hover) {
      &:hover {
        .account__icon {
          --svg-fill-color: ${colors.BLACK};
        }
      }
    }
  }
  .header-cart__link {
    @media (hover: hover) {
      &:hover {
        .shopping-cart__icon {
          --svg-fill-color: ${colors.BLACK};
        }
      }
    }
  }
  .header-mini-wishlist__link {
    @media (hover: hover) {
      &:hover {
        .wishlist__icon {
          --svg-fill-color: ${colors.BLACK};
        }
      }
    }
  }
  .account__icon,
  .wishlist__icon,
  .shopping-cart__icon {
    color: transparent;
    --svg-fill-color: transparent;
    @media (min-width: ${breakpoints.M - 1}px) {
      width: 20px;
      height: 20px;
    }
  }
  .link-menu {
    font-size: 1.4rem;
    line-height: 1.8rem;
    display: inline-block;
    font-weight: 500;
    text-decoration: none;
    max-width: fit-content;
    &.link-menu--second-level {
      text-transform: uppercase;
    }
    &.link-menu--third-level {
      color: ${colors.MAIN_GREY};
      &:after {
        background: ${colors.MAIN_GREY};
      }
    }
    &:hover {
      &:after {
        transform: scaleX(1);
        transform-origin: 0 100%;
      }
    }
    &:after {
      content: '';
      height: 1px;
      background: ${colors.BLACK};
      display: block;
      transition: transform 0.4s;
      transform: scaleX(0);
      transform-origin: 100% 0;
      position: relative;
      top: 0;
    }
    &:before {
      display: block;
      content: attr(title);
      height: 0;
      overflow: 'hidden';
      visibility: 'hidden';
    }
  }

  .desktop-header__right-side {
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 2px 0;
    flex: 1 0 auto;
    justify-content: flex-end;
  }
  .desktop-fake-input__container {
    z-index: ${zIndex.SEARCH};
    padding: 3px 14px;
    color: ${colors.WHITE};
    border: 1px solid ${colors.WHITE};
    border-radius: 15px;
    width: 196px;
    transition: all 0.4s;
  }
  .desktop-input__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    color: ${colors.LIGHT};
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding: 0;
    flex: 1 1 auto;
    text-decoration: none;
  }
  #desktop-header {
    display: none;
    @media (min-width: ${breakpoints.M - 1}px) {
      display: flex;
    }
  }
  #mobile-header {
    display: block;
    @media (min-width: ${breakpoints.M - 1}px) {
      display: none;
    }
  }
`;

export const Header = () => {
  const { pathname } = useLocation();
  const { isMobile } = useMediaQueries();
  const isTransparentHeader =
    pathname === paths.HOME ||
    pathname.startsWith(paths.OUR_BRAND) ||
    (isMobile && pathname.startsWith(paths.PRODUCT));
  const shouldHaveBackgroundWhiteBehindContainer =
    pathname.startsWith(paths.CATALOG) ||
    pathname.startsWith(paths.SELECTION) ||
    pathname.startsWith(paths.TOP_TEN_PAGE) ||
    pathname.startsWith(paths.SEARCH_RESULTS);
  const isSearchPage = pathname === paths.SEARCH_RESULTS;
  const [scrolled, setScrolled] = React.useState(false);
  const placeholder = useSelector((state: RootState) => state.cms.search?.placeholder) ?? '';

  useEffect(() => {
    function scrollListener() {
      setScrolled(window.scrollY > 0);
    }

    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  return (
    <HeaderContainer
      className={
        shouldHaveBackgroundWhiteBehindContainer ? 'header-container--white-background' : ''
      }
    >
      <div
        id="desktop-header"
        className={[
          'header',
          isTransparentHeader ? 'header--is-transparent' : '',
          scrolled ? 'header--is-activate' : '',
        ].join(' ')}
      >
        <div className="desktop-header__left-side">
          <Logo />
          <div className="desktop-menu">
            <Menu />
          </div>
        </div>
        <div className="desktop-header__right-side">
          {!isSearchPage && (
            <div className="desktop-fake-input__container">
              <Link
                to={paths.SEARCH_RESULTS}
                data-cy="search-result-link"
                className="desktop-input__link"
              >
                {placeholder}
                <Icon name="search" className="input-search__icon" />
              </Link>
            </div>
          )}
          <HeaderRightSide />
        </div>
      </div>

      <div
        id="mobile-header"
        className={[
          'header',
          isTransparentHeader ? 'header--is-transparent' : '',
          scrolled ? 'header--is-activate' : '',
        ].join(' ')}
      >
        <HeaderRightSide />
      </div>
      <ScrollBackToTopButton />
    </HeaderContainer>
  );
};
