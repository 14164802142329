import * as React from 'react';
import styled from '@emotion/styled';
import { colors } from '../systemprovider/constants';
import { getIconUrl } from '../icon/utils';

type AccordionItemType = {
  title: React.ReactNode;
  content: React.ReactNode;
  index: number;
};
type Props = {
  id: string;
  items: AccordionItemType[];
  isTitleCentered?: boolean;
  isPLPFilter?: boolean;
  iconToOpen?: string;
  iconToClose?: string;
  iconSize?: number;
  separateLineColor?: string;
};
type StyledProps = {
  $isTitleCentered?: boolean;
  $isPLPFilter?: boolean;
  $iconToOpen?: string;
  $iconToClose?: string;
  $iconSize?: number;
  $separateLineColor?: string;
};

const StyledAccordion = styled.div<StyledProps>`
  max-width: calc(100vw - 16px);
  .accordion-item {
    position: relative;
    &::after {
      content: '';
      margin: ${(props) => (props.$isPLPFilter ? '8px 0' : '0')};
      display: block;
      width: 100%;
      height: 1px;
      background-color: ${colors.LIGHT};
      background-color: ${(props) => props.$separateLineColor ?? colors.LIGHT}px;
    }
    &[open] {
      .accordion-item__toggle-icon {
        background-image: url(${(props) => getIconUrl(props.$iconToClose ?? 'chevronDown')});
        transform: rotate(180deg);
      }
    }
    .accordion-item__toggle-icon {
      background-image: url(${(props) => getIconUrl(props.$iconToOpen ?? 'chevronDown')});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: ${(props) => props.$iconSize ?? 17}px;
      height: ${(props) => props.$iconSize ?? 17}px;
      transition: all 0.25s ease-in-out;
    }
  }
  .accordion-item__header {
    display: flex;
    justify-content: ${(props) => (props.$isTitleCentered ? 'center' : 'space-between')};
    cursor: pointer;
    align-items: center;
    padding: 16px 0;
    &::-webkit-details-marker {
      display: none;
    }
  }
  .accordion-item__title {
    color: ${colors.BLACK};
    font-size: 14px;
    line-height: 21px;
    font-weight: bold;
    margin-right: 8px;
    flex: 1 0 auto;
    text-align: ${(props) => (props.$isTitleCentered ? 'center' : 'left')};
  }
`;
export const Accordion = ({
  id,
  items = [],
  isTitleCentered,
  isPLPFilter,
  iconToOpen,
  iconToClose,
  iconSize,
  separateLineColor,
  ...rest
}: Props) => {
  const children = items.map((item) => {
    return item.title ? (
      <details
        className="accordion-item"
        key={`${item.title}-${item.index}`}
        id={`${id}-child-${item.index}`}
        open={isPLPFilter && item.index === 0}
      >
        <summary className="accordion-item__header">
          <div className="accordion-item__title">{item.title}</div>
          <div className="accordion-item__toggle-icon" />
        </summary>
        <div className="accordion-item__content-container">{item.content}</div>
      </details>
    ) : null;
  });
  return (
    <StyledAccordion
      id={id}
      $isTitleCentered={isTitleCentered}
      $isPLPFilter={isPLPFilter}
      $iconToClose={iconToClose}
      $iconToOpen={iconToOpen}
      $iconSize={iconSize}
      $separateLineColor={separateLineColor}
      {...rest}
    >
      {children}
    </StyledAccordion>
  );
};
