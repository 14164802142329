import React from 'react';
import styled from '@emotion/styled';

import { Icon, colors } from '../../../design-system';
import { MobileMenuTreeItem } from '../types';
import { Link, useLocation } from 'react-router-dom';

type Props = MobileMenuTreeItem & {
  haveSubMenu?: boolean;
  onMenuClick?: () => void;
  onClickClose: () => void;
  fontSize: string;
};

const StyledMobileMenuItem = styled.button<Props>`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  height: 44px;
  text-decoration: none;
  gap: 4px;
  border: none;
  background: none;
  width: 100%;
  &.mobile-menu__item--level-1,
  &.mobile-menu__item--level-2 {
    .mobile-menu__title {
      text-transform: uppercase;
    }
  }
  .mobile-menu__icon-container {
    width: 24px;
    height: 24px;
  }
  .mobile-menu__title {
    font-size: ${(props) => props.fontSize};
    line-height: 1.8rem;
    font-weight: 500;
    margin: 0;
    color: ${colors.BLACK};
    border-bottom: ${(props) => (props.isPrimaryLink ? '1px solid' : 'none')};
  }
  .mobile-menu__arrow-container {
    margin-left: auto;
  }
`;

const StyledMobileMenuItemLink = StyledMobileMenuItem.withComponent(Link);

export const MobileMenuItem = ({
  name,
  picto_for_mobile,
  link,
  haveSubMenu,
  onMenuClick,
  isPrimaryLink,
  subLevel,
  level,
  onClickClose,
  fontSize,
}: Props) => {
  const isLink = link?.startsWith('http://') || link?.startsWith('https://') || !haveSubMenu;
  const { pathname } = useLocation();
  const isCurrentPage = pathname === link || pathname === `/${link}`;

  const itemProps = {
    'data-testid': `link-menu-${name}`,
    name,
    link,
    picto_for_mobile,
    haveSubMenu,
    onClick: haveSubMenu ? onMenuClick : onClickClose,
    isPrimaryLink,
    subLevel,
    level,
    onClickClose,
    className: level ? `mobile-menu__item--level-${level}` : '',
  };

  return isLink && !isCurrentPage ? (
    <StyledMobileMenuItemLink
      {...itemProps}
      fontSize={fontSize}
      to={link ?? '#'}
      aria-label={`Lien vers la page ${name}`}
    >
      {(picto_for_mobile || level === 1) && (
        <div className="mobile-menu__icon-container">
          {picto_for_mobile && <Icon name={picto_for_mobile} size={24} />}
        </div>
      )}
      <p className="mobile-menu__title">{name}</p>
    </StyledMobileMenuItemLink>
  ) : (
    <StyledMobileMenuItem {...itemProps} fontSize={fontSize}>
      {(picto_for_mobile || level === 1) && (
        <div className="mobile-menu__icon-container">
          {picto_for_mobile && <Icon name={picto_for_mobile} size={24} />}
        </div>
      )}
      <p className="mobile-menu__title">{name}</p>
      {haveSubMenu && (
        <div className="mobile-menu__arrow-container">
          <Icon name="chevronRight" size={16} />
        </div>
      )}
    </StyledMobileMenuItem>
  );
};
