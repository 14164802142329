import React from 'react';

export const detailedInfosProductShape = [
  {
    picto: (
      <svg
        width="115"
        height="115"
        viewBox="0 0 115 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" fill="#F9F9F9" />
        <g clipPath="url(#clip0_1016_1111)">
          <path
            d="M59.293 74.7436C59.293 74.7436 65.9063 57.1081 82.0722 51.2296"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M57.0883 74.7436C57.0883 74.7436 49.7401 57.1081 33.5742 51.2296"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M57.8238 80.6221C57.8238 80.6221 37.7266 106.061 19.2828 84.0905C17.5328 82.0008 16.5684 79.3651 16.5566 76.6395C16.5566 69.5044 17.1665 54.7934 22.8026 49.4513C22.9357 49.3375 23.105 49.2749 23.2802 49.2749C23.4553 49.2749 23.6247 49.3375 23.7578 49.4513C27.5642 52.4567 52.3862 72.4878 57.8238 80.6221Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M24.5585 42.4118H19.0474C18.5401 42.4118 18.1289 42.8231 18.1289 43.3303V43.6977C18.1289 44.205 18.5401 44.6163 19.0474 44.6163H24.5585C25.0658 44.6163 25.477 44.205 25.477 43.6977V43.3303C25.477 42.8231 25.0658 42.4118 24.5585 42.4118Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path d="M23.6409 42.4118V20.3674H19.9668V42.4118" stroke="black" strokeMiterlimit="10" />
          <path
            d="M97.1365 44.6163C97.1365 44.6163 100.862 58.5042 102.464 60.2604C102.576 60.3884 102.636 60.5534 102.633 60.7234L101.178 88.3377C101.178 88.3377 96.3429 89.3591 93.4624 91.6443C88.6935 95.4213 81.0882 96.4721 69.2136 93.8488C65.9069 93.114 57.824 93.114 57.824 93.114C53.4151 93.114 49.4618 93.114 46.8018 93.8488C35.0447 97.1554 27.3071 95.4213 22.5529 91.6443C19.6577 89.3591 14.8227 88.3377 14.8227 88.3377L13.3677 60.7234C13.3607 60.5528 13.4215 60.3864 13.5367 60.2604C15.1386 58.5042 18.8788 44.6163 18.8788 44.6163"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M58.1914 80.6221C58.1914 80.6221 78.2739 106.061 96.7177 84.0905C98.4677 82.0008 99.4321 79.3651 99.4439 76.6394C99.4439 69.5044 98.834 54.7934 93.1979 49.4513C93.0643 49.3355 92.8934 49.2718 92.7166 49.2718C92.5398 49.2718 92.3689 49.3355 92.2353 49.4513C88.4363 52.4567 63.6143 72.4877 58.1914 80.6221Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M91.2585 44.6163C76.9296 54.9037 57.8244 76.9481 57.8244 76.9481C57.8244 76.9481 39.4541 54.9037 24.7578 44.6163"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M91.4415 44.6163H96.9526C97.4599 44.6163 97.8711 44.205 97.8711 43.6977V43.3303C97.8711 42.8231 97.4599 42.4118 96.9526 42.4118H91.4415C90.9342 42.4118 90.5229 42.8231 90.5229 43.3303V43.6977C90.5229 44.205 90.9342 44.6163 91.4415 44.6163Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path d="M92.3594 42.4118V20.3674H96.0335V42.4118" stroke="black" strokeMiterlimit="10" />
          <path
            d="M92.7136 51.2296C93.3223 51.2296 93.8158 50.7361 93.8158 50.1274C93.8158 49.5186 93.3223 49.0251 92.7136 49.0251C92.1048 49.0251 91.6113 49.5186 91.6113 50.1274C91.6113 50.7361 92.1048 51.2296 92.7136 51.2296Z"
            fill="black"
          />
          <path
            d="M81.2871 58.4308C81.2871 58.4308 93.7789 57.1081 98.1878 61.517C98.1878 61.517 92.3093 67.3955 89.3701 68.1303C89.4215 68.1303 86.4014 58.2838 81.2871 58.4308Z"
            fill="#F2F2F2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M81.287 58.4308L81.2344 57.9335L81.3014 58.9305C82.4072 58.8988 83.4352 59.4043 84.3801 60.2558C85.3261 61.1081 86.1504 62.2744 86.8309 63.4724C87.5092 64.6665 88.0317 65.8689 88.3815 66.7743C88.5369 67.1764 88.6575 67.5181 88.742 67.7719L89 68.5L89.37 68.6303C89.433 68.6303 89.4884 68.6203 89.5369 68.6036C90.3522 68.3889 91.3135 67.8488 92.2685 67.2007C93.2537 66.5322 94.2793 65.7159 95.2049 64.929C96.1318 64.1412 96.965 63.3774 97.5662 62.8109C97.867 62.5274 98.1101 62.293 98.2783 62.1292C98.3624 62.0472 98.4278 61.9829 98.4724 61.9389L98.5233 61.8885L98.5365 61.8753L98.5409 61.8709C98.5409 61.8709 98.5413 61.8705 98.1878 61.517L98.5409 61.8709L98.8949 61.517L98.5413 61.1634C97.3522 59.9743 95.6505 59.1997 93.8205 58.6929C91.9835 58.1842 89.9709 57.9328 88.114 57.8214C86.2548 57.7099 84.5374 57.7378 83.2856 57.7935C82.6592 57.8213 82.1483 57.8561 81.7932 57.8841C81.6156 57.898 81.4769 57.9103 81.3821 57.9191C81.3347 57.9235 81.2983 57.9271 81.2734 57.9296L81.245 57.9324L81.2374 57.9332L81.2344 57.9335C81.2344 57.9335 81.2344 57.9335 81.287 58.4308ZM84.0765 58.7651C84.418 58.9835 84.7426 59.2363 85.0495 59.5129C86.1066 60.4654 86.993 61.7332 87.7004 62.9785C88.41 64.2276 88.9526 65.4776 89.3143 66.4139C89.4835 66.8517 89.614 67.223 89.7041 67.4963C90.2757 67.2716 90.9615 66.8791 91.707 66.3732C92.6507 65.7328 93.6459 64.9418 94.5573 64.1671C95.4674 63.3935 96.2875 62.6417 96.8804 62.0831C97.1113 61.8655 97.3075 61.6774 97.4609 61.529C96.4644 60.6927 95.1024 60.0855 93.5536 59.6566C91.8083 59.1733 89.8713 58.9287 88.0541 58.8196C86.5839 58.7314 85.2015 58.7325 84.0765 58.7651ZM89.2759 67.6383C89.2516 67.6426 89.2286 67.6485 89.207 67.6557L89.2487 67.6452C89.2577 67.643 89.2668 67.6407 89.2759 67.6383Z"
            fill="black"
          />
          <path
            d="M90.1411 66.6607C90.5469 66.6607 90.8759 66.3317 90.8759 65.9259C90.8759 65.52 90.5469 65.191 90.1411 65.191C89.7352 65.191 89.4062 65.52 89.4062 65.9259C89.4062 66.3317 89.7352 66.6607 90.1411 66.6607Z"
            stroke="black"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    ),
    shapeName: `Soutien-gorge d'allaitement`,
    shapeDescription: `Le soutien-gorge d'allaitement permet grâce à ses ouvertures faciles, un allaitement simple et discret. Il maintient la poitrine, prévient les fuites de lait et aide à prévenir le relâchement cutané tout en offrant un confort optimal.`,
  },
  {
    picto: (
      <svg
        width="116"
        height="115"
        viewBox="0 0 116 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" transform="translate(0.5)" fill="#F9F9F9" />
        <g clipPath="url(#clip0_3774_1579)">
          <path
            d="M82.0504 43L78.2476 60.2607C78.0416 61.0895 78.0012 61.9501 78.1287 62.7942L81.5476 85.2574C81.56 85.3106 81.5525 85.3665 81.5265 85.4147C81.5004 85.4628 81.4576 85.5 81.4059 85.5194C79.3537 86.2781 61.08 93.3413 58.7718 105.594C58.763 105.645 58.7361 105.691 58.6959 105.724C58.6556 105.757 58.6047 105.775 58.5524 105.774H55.7277C55.6753 105.775 55.6244 105.757 55.5842 105.724C55.5439 105.691 55.517 105.645 55.5083 105.594C53.2001 93.3549 34.9264 86.2781 32.8741 85.5194C32.8224 85.5 32.7796 85.4628 32.7535 85.4147C32.7275 85.3665 32.72 85.3106 32.7324 85.2574L36.5764 63.2413C36.7234 62.399 36.7126 61.5372 36.5444 60.6987L34.0579 41.6452C31.7726 33.9678 34.0579 25.3871 34.0579 25.3871V8.22583H35.4291V24.0323C35.4291 24.0323 52.7978 32.1613 56.9115 38.9355H59.1968C63.3105 32.1613 80.6792 24.0323 80.6792 24.0323V8.22583H82.0504V25.3871C82.0504 25.3871 84.7928 34.4194 82.0504 43Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M55.9982 43.4517C55.9982 43.4517 49.2427 52.4839 37.9941 44.3549"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M59.9238 43.4517C59.9238 43.4517 66.6793 52.4839 77.9279 44.3549"
            stroke="black"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    ),
    shapeName: 'Body',
    shapeDescription: `Il se porte sous toutes les tenues, des plus décontractées aux plus habillées, et s'adapte à toutes les morphologies`,
  },
  {
    picto: (
      <svg
        width="115"
        height="115"
        viewBox="0 0 115 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" fill="#F9F9F9" />
        <g clipPath="url(#clip0_1016_1298)">
          <path
            d="M10.4491 51.1281C9.63263 62.5591 10.7921 77.599 14.5316 89.5036"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M104.346 51.9445C105.318 63.8328 104.909 79.1422 101.08 89.5036"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M62.1094 80.5221C62.1094 80.5221 78.219 91.1367 93.7325 74.8066"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M21.2832 74.8066C36.7967 91.1367 52.9063 80.5221 52.9063 80.5221"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M52.9883 80.5221C52.9883 80.5221 54.7029 86.3193 52.9883 89.5036"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M61.9127 80.5221C61.9127 80.5221 60.2062 86.3193 61.9127 89.5036"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M57.5365 80.5221H52.6375C26.7789 68.2746 10.4488 54.4839 10.4488 53.5776C10.1304 53.5776 12.0818 25.8165 12.8984 25.8165L56.72 25.4082H98.9985C99.9783 25.4164 104.714 53.5776 104.347 53.5776C104.347 55.2106 92.9155 60.9261 62.1089 80.5221H57.5365Z"
            stroke="black"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    ),
    shapeName: 'Boxer',
    shapeDescription: `Le boxer offre un confort inégalable et une grande liberté de mouvement grâce à sa coupe ample et sa matière douce, tout en s'adaptant à différents styles et occasions.`,
  },
  {
    picto: (
      <svg
        width="115"
        height="115"
        viewBox="0 0 115 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" fill="#F9F9F9" />
        <g clipPath="url(#clip0_1016_1037)">
          <path
            d="M64.8125 59.3543C69.7096 55.1453 75.7896 52.5556 82.2176 51.9407"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M49.5662 59.3542C46.0424 56.6009 40.8941 54.0153 33.4043 52.7034"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M91.9493 48.272L95.5645 18.3814H98.6154L95.9154 49.4314C95.7059 51.7776 96.3386 54.1218 97.7001 56.0441V56.0441C101.45 61.4639 103.143 68.0441 102.475 74.6009L101.285 94.2712C86.856 95.9881 72.3408 96.8794 57.8103 96.9407C43.4037 96.9689 29.01 96.0773 14.7171 94.2712L13.512 74.6314C12.8434 68.0746 14.5364 61.4944 18.2865 56.0746V56.0746C19.6481 54.1523 20.2808 51.8081 20.0713 49.4619L17.3865 18.3814H20.4374L24.5026 49.2254C24.6593 50.4665 25.1322 51.6466 25.8762 52.6523C26.6201 53.658 27.61 54.4556 28.751 54.9687C37.9035 58.9805 48.4365 59.5449 57.8103 59.5449C68.801 59.5449 73.606 58.3093 86.7094 54.4424C88.0879 54.0342 89.3176 53.2332 90.2481 52.1374C91.1787 51.0416 91.7698 49.6984 91.9493 48.272V48.272Z"
            stroke="black"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    ),
    shapeName: 'Brassière',
    shapeDescription: `La brassière offre un confort optimal et un soutien personnalisé. Elle s'adapte à toutes les morphologies et activités grâce à sa conception sans armatures, elle permet une grande liberté de mouvement tout en assurant un maintien parfait.`,
  },
  {
    picto: (
      <svg
        width="115"
        height="115"
        viewBox="0 0 115 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" fill="#F9F9F9" />
        <g clipPath="url(#clip0_1016_1052)">
          <path
            d="M59.1074 74.4252C60.8315 69.6827 63.5741 65.3758 67.1423 61.8077C70.7104 58.2395 75.0173 55.4969 79.7598 53.7728"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M56.8945 74.4252C55.1705 69.6827 52.4278 65.3758 48.8597 61.8077C45.2915 58.2395 40.9846 55.4969 36.2422 53.7728"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M100.773 63.7302C66.8444 45.2906 59.0998 75.1628 59.0998 75.1628H56.887C56.887 75.1628 49.1424 45.2906 15.2135 63.7302L13.377 66.3117L14.8521 89.1769C14.8521 89.1769 21.4904 95.0775 26.6535 95.8151C30.6807 96.4199 33.926 96.951 42.8803 94.3399C47.7918 92.897 52.8818 92.1521 58.0008 92.1272C63.1198 92.1521 68.2098 92.897 73.1213 94.3399C78.3703 95.9469 83.8953 96.4492 89.3481 95.8151C95.2488 95.0775 101.149 89.1769 101.149 89.1769L102.625 66.3117L100.773 63.7302Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M15.5905 64.8366C15.5905 64.8366 13.3777 89.9144 28.1294 93.6024C31.5518 94.4579 39.4145 93.7351 42.8811 92.8648C48.7818 91.3896 55.42 87.7017 56.8952 75.1628"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M101.032 64.8366C101.032 64.8366 103.244 89.9144 88.4928 93.6024C85.0704 94.4579 77.1709 93.8679 73.7411 92.8648C68.696 91.3896 62.0578 87.7017 59.1074 75.1628"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M16.2838 60.1677L15.5905 60.4111L15.5684 59.6735"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M15.53 58.1688L14.5195 19.8587"
            stroke="black"
            strokeMiterlimit="10"
            strokeDasharray="2.04 2.04"
          />
          <path d="M14.5045 19.1064L14.4824 18.3688H15.22" stroke="black" strokeMiterlimit="10" />
          <path d="M16.3281 18.3688H17.0657L17.0878 19.1064" stroke="black" strokeMiterlimit="10" />
          <path
            d="M17.123 20.5742L18.1704 58.0213"
            stroke="black"
            strokeMiterlimit="10"
            strokeDasharray="1.99 1.99"
          />
          <path
            d="M18.1927 58.7515L18.2148 59.4891L17.5215 59.7325"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M100.5 60.1677L101.193 60.4111L101.208 59.6735"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M101.252 58.1688L102.262 19.8587"
            stroke="black"
            strokeMiterlimit="10"
            strokeDasharray="2.04 2.04"
          />
          <path d="M102.278 19.1064L102.3 18.3688H101.562" stroke="black" strokeMiterlimit="10" />
          <path d="M100.457 18.3688H99.7194L99.6973 19.1064" stroke="black" strokeMiterlimit="10" />
          <path
            d="M99.6513 20.5742L98.6113 58.0213"
            stroke="black"
            strokeMiterlimit="10"
            strokeDasharray="1.99 1.99"
          />
          <path
            d="M98.5905 58.7515L98.5684 59.4891L99.2617 59.7325"
            stroke="black"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    ),
    shapeName: 'Soutien-gorge bretelles amovibles',
    shapeDescription: `Le soutien gorge à bretelles amovibles est idéal pour les robes bustier ou les hauts à bretelles fines, il s'adapte à toutes les occasions et permet de créer des looks variés. Il offre la possibilité de croiser les bretelles dans le dos, de les retirer complètement ou de les ajuster selon vos préférences pour un confort optimal.`,
  },
  {
    picto: (
      <svg
        width="116"
        height="115"
        viewBox="0 0 116 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" transform="translate(0.5)" fill="#F9F9F9" />
        <path
          d="M82.0857 76.6536L86.165 103.456M27.9199 103.456L32.5065 77.187"
          stroke="#E00730"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M82.0883 73.6306L86.6258 53.0356C89.898 42.7974 86.6258 32.0203 86.6258 32.0203V11.5439H84.9897V30.4038C84.9897 30.4038 64.2657 40.1031 59.3575 48.1859H56.6306C51.7223 40.1031 30.9984 30.4038 30.9984 30.4038V11.5439H29.3623V32.0203C29.3623 32.0203 26.6355 42.2585 29.3623 51.419L32.3291 74.1533C32.5298 75.1537 32.5427 76.182 32.3673 77.187L31.1307 84.2697C39.7084 83.3557 48.7942 82.865 58.1997 82.865C66.8062 82.865 75.1449 83.2759 83.0716 84.0459L81.9465 76.6535C81.7944 75.6464 81.8425 74.6195 82.0883 73.6306Z"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M55.6792 53.5745C55.6792 53.5745 47.6187 64.3515 34.1973 54.6522"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M60.3633 53.5745C60.3633 53.5745 68.4238 64.3515 81.8452 54.6522"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M58.1346 95.814C57.7 95.814 57.3477 95.3009 57.3477 94.6679C57.3477 94.035 57.7 93.5219 58.1346 93.5219C58.4075 93.5219 58.6479 93.7242 58.7891 94.0314"
          stroke="#E00730"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M42.0471 36.0115C40.6703 39.133 38.7428 47.9652 42.0471 58.3214C45.3515 68.6775 44.5863 79.3459 43.7907 83.3855"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M74.0896 36.0115C75.4664 39.133 77.3939 47.9652 74.0896 58.3214C70.7852 68.6775 71.5504 79.3459 72.346 83.3855"
          stroke="black"
          strokeMiterlimit="10"
        />
      </svg>
    ),
    shapeName: 'Soutien-gorge bustier',
    shapeDescription: `Le soutien-gorge bustier est pièce élégante et structurée, offrant un maintien optimal grâce à sa forme allongée sous la poitrine. Il combine confort et sensualité avec des baleines intégrées. `,
  },
  {
    picto: (
      <svg
        width="115"
        height="115"
        viewBox="0 0 115 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" fill="#F9F9F9" />
        <g clipPath="url(#clip0_1016_1079)">
          <path
            d="M102.041 72.4564L103.938 73.6023C104.158 73.7321 104.337 73.9202 104.456 74.1457C104.575 74.3713 104.629 74.6254 104.612 74.8798L103.892 86.0367C103.892 86.0367 100.795 86.4238 99.2464 87.5852C99.2464 87.5852 81.4387 101.909 65.1795 86.0367H52.4045C35.7582 101.909 18.3377 87.5852 18.3377 87.5852C17.7188 87.0103 16.984 86.5747 16.1827 86.3076C15.3814 86.0405 14.5322 85.9481 13.6922 86.0367L13.3205 75.3056C13.3148 74.9877 13.4157 74.677 13.607 74.423L15.3568 72.1002"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M15.3572 72.1002C14.854 59.3252 20.2737 50.8085 20.2737 50.8085V21.3871H23.3707V50.8085C46.598 57.7767 56.6632 75.5844 56.6632 75.5844H60.9216C60.9216 75.5844 70.5997 57.7767 94.2142 50.8085V21.3871H97.3112V50.8085C97.3112 50.8085 101.825 62.4222 102.081 72.1002"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M15.3574 72.1002C15.7579 76.1957 17.036 80.1568 19.1048 83.7139C20.5218 85.9874 22.5688 87.8004 24.9968 88.9324C31.6321 92.0294 47.1325 96.4193 56.6634 75.5611"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M102.08 72.1002C101.677 76.3653 100.308 80.4825 98.077 84.1398C96.7113 86.2058 94.8007 87.8537 92.5566 88.9014C85.991 91.9984 70.3667 96.5664 60.8125 75.5843"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M59.7598 74.0359C61.5695 69.0577 64.4485 64.5367 68.194 60.7912C71.9395 57.0457 76.4605 54.1667 81.4387 52.357"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M57.4367 74.0359C55.627 69.0577 52.748 64.5367 49.0025 60.7912C45.257 57.0457 40.736 54.1667 35.7578 52.357"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    ),
    shapeName: 'Soutien-gorge coques',
    shapeDescription: `Le soutien-gorge coques offre du confort et galbe la poitrine pour lui donner un aspect arrondi, tout en invisibilité.`,
  },
  {
    picto: (
      <svg
        width="115"
        height="115"
        viewBox="0 0 115 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" fill="#F9F9F9" />
        <g clipPath="url(#clip0_1016_996)">
          <path
            d="M99.9755 63.7563C100.732 87.958 86.3621 90.9832 86.3621 90.9832C61.4041 96.2773 59.8915 75.8572 59.8915 75.8572C68.2108 62.2437 96.194 59.9748 96.194 59.9748V18.3782H99.2192V60.3529L102.623 64.1345V86.0672C101.761 86.3922 100.985 86.9096 100.354 87.5798C96.5722 91.3614 91.2781 98.1681 75.3957 95.1429C68.5436 93.8345 65.5638 89.8487 58.0008 89.8487C50.4377 89.8487 47.4579 93.8345 40.6058 95.1429C24.7234 98.1681 19.4293 91.3614 15.6478 87.5798C15.0165 86.9096 14.2405 86.3922 13.3789 86.0672V64.1345L16.7823 60.3529V18.3782H19.8075V59.9748C19.8075 59.9748 47.7907 62.2437 56.11 75.8572C56.11 75.8572 54.5974 96.2773 29.6394 90.9832C29.6394 90.9832 15.2697 87.958 16.026 63.7563"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path d="M56.1094 76.2353H59.8909" stroke="black" strokeMiterlimit="10" />
          <path
            d="M58 73.2101C58 73.2101 63.2941 57.3277 82.958 55.0588"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M58.0001 73.2101C58.0001 73.2101 55.7312 59.5967 34.5547 55.8151"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    ),
    shapeName: 'Soutien-gorge corbeille',
    shapeDescription: `Le soutien-gorge corbeille arrondit la courbe des seins et porte le volume vers le haut, offrant un joli décolleté dégagé et galbé garantissant un confort au quotidien.`,
  },
  {
    picto: (
      <svg
        width="115"
        height="115"
        viewBox="0 0 115 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" fill="#F9F9F9" />
        <g clipPath="url(#clip0_1016_1317)">
          <path
            d="M10.4253 54.6209C9.79661 66.8021 10.311 82.2163 14.6707 96.0875"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M103.711 54.3433C105.262 67.4797 105.703 83.9308 101.213 96.0875"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M62.252 87.1067C62.252 87.1067 78.3683 97.7203 93.8805 81.3917"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M21.4316 81.3917C36.9438 97.7203 53.0602 87.1067 53.0602 87.1067"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M53.1172 87.1067C53.1172 87.1067 54.8317 92.9034 53.1172 96.0875"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M62.0396 87.1067C62.0396 87.1067 60.3333 92.9034 62.0396 96.0875"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M57.6715 87.1067H52.7239C26.9165 74.8603 10.5879 55.3965 10.5879 54.4495C10.5879 54.4495 14.5966 27.2541 15.9274 20.7308C16.0038 20.342 16.2191 19.9942 16.5331 19.7524C16.847 19.5105 17.2382 19.3911 17.6337 19.4164C23.561 19.7348 47.5559 21.0084 57.1245 20.9758C66.4155 20.9349 88.7367 19.7185 94.2477 19.4082C94.628 19.383 95.0052 19.4916 95.3139 19.7153C95.6225 19.939 95.8432 20.2636 95.9377 20.6329C97.6848 26.952 103.661 54.4576 103.661 54.4576C103.661 56.0905 93.0475 67.5205 62.2354 87.1149H57.6715V87.1067Z"
            stroke="black"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    ),
    shapeName: 'Culotte',
    shapeDescription: `La culotte est le basique, l'indispensable dans toute garde-robe. Elle se distingue par sa simplicité, son confort et sa polyvalence.`,
  },
  {
    picto: (
      <svg
        width="116"
        height="115"
        viewBox="0 0 116 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" transform="translate(0.5)" fill="#F9F9F9" />
        <path
          d="M56.5975 11.7621C56.5975 11.7621 48.6292 22.4158 35.3613 12.8275"
          stroke="#E00730"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M61.2285 11.7621C61.2285 11.7621 69.1968 22.4158 82.4647 12.8275"
          stroke="#E00730"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M88.6419 103.438C89.9336 87.5034 88.4323 71.4191 86.8503 61.4501M60.8903 104.779C60.0996 98.526 59.5441 91.2278 59.602 84.8873"
          stroke="#E00730"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M86.1496 11.8191L81.6641 32.1785C81.4211 33.1561 81.3735 34.1712 81.5239 35.1669M34.871 35.6942C35.0444 34.7007 35.0316 33.6841 34.8332 32.6952L31.9004 10.2211"
          stroke="#E00730"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M27.3561 103.438C26.0711 87.5858 27.5503 71.5855 29.1232 61.6052M55.1804 104.779C55.971 98.526 56.4596 91.248 56.4017 84.9076"
          stroke="#E00730"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M81.6615 32.1785L82.3565 29.0241C82.4183 28.7435 82.3642 28.4458 82.1527 28.2514C79.8503 26.1353 70.7051 24.5458 58.4725 24.5458C45.5066 24.5458 35.9161 26.3316 34.4496 28.6375C34.3585 28.7808 34.3427 28.9557 34.3646 29.124L34.8307 32.6952C35.0216 33.6471 35.0406 44.8639 34.8871 46.5893C34.8776 46.6969 34.8446 46.7906 34.7938 46.8859C34.1423 48.1091 29.4372 57.0835 29.1548 61.6628C28.8638 66.3825 54.1788 76.9192 56.2472 84.7398C56.3884 85.2737 56.829 85.7235 57.3812 85.7235H58.5362C59.0884 85.7235 59.5344 85.2728 59.7276 84.7554C62.1869 78.1688 87.4986 66.6339 86.8693 61.5558C86.2578 56.6206 82.7601 48.1503 82.2365 46.902C82.1917 46.7951 82.1677 46.6874 82.1612 46.5717L81.5213 35.1668C81.3709 34.1712 81.4186 33.1561 81.6615 32.1785Z"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M30.1807 28.3555C30.748 31.8811 31.1589 35.5863 31.1589 38.8507C31.1589 49.6784 27.5935 55.8325 24.6035 60.4467"
          stroke="black"
          strokeMiterlimit="10"
          strokeDasharray="1.99 1.99"
        />
        <path
          d="M86.4423 28.3555C85.875 31.8811 85.4642 35.5863 85.4642 38.8507C85.4642 49.6784 89.0296 55.8325 92.0195 60.4467"
          stroke="black"
          strokeMiterlimit="10"
          strokeDasharray="1.99 1.99"
        />
      </svg>
    ),
    shapeName: 'Culotte galbante',
    shapeDescription: `La culotte galbante sculpte la silhouette en offrant un maintien au niveau du ventre et des hanches. Elle lisse les courbes, créant un effet ventre plat et fesses galbées, tout en restant confortable à porter au quotidien.`,
  },
  {
    picto: (
      <svg
        width="116"
        height="115"
        viewBox="0 0 116 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" transform="translate(0.5)" fill="#F9F9F9" />
        <g clipPath="url(#clip0_3774_1459)">
          <path
            d="M10.9253 54.621C10.2966 66.8021 10.811 82.2164 15.1707 96.0875"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M104.211 54.3434C105.762 67.4798 106.203 83.9309 101.713 96.0875"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M56.5 87.0001C56.5 87.0001 61 97.9728 56.5 104"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M59.8026 87.0001C59.8026 87.0001 58.8939 90.1582 58.2149 93.5087"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M58.1715 87.1068H56.6552C34.3246 66.2871 11.0879 59.2715 11.0879 54.4495C11.0879 54.4495 15.0966 27.2542 16.4274 20.7309C16.5038 20.342 16.7191 19.9942 17.0331 19.7524C17.347 19.5105 17.7382 19.3911 18.1337 19.4164C24.061 19.7348 48.0559 21.0085 57.6245 20.9758C66.9155 20.935 89.2367 19.7185 94.7477 19.4082C95.128 19.383 95.5052 19.4916 95.8139 19.7153C96.1225 19.939 96.3432 20.2636 96.4377 20.6329C98.1848 26.9521 104.161 54.4577 104.161 54.4577C104.161 56.642 83.0101 59.3262 59.6022 87.1149H58.1715V87.1068Z"
            stroke="black"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M62.5207 64.6223C62.5207 65.8214 62.0443 66.9713 61.1965 67.8192C60.3486 68.667 59.1987 69.1434 57.9996 69.1434C56.8005 69.1434 55.6506 68.667 54.8027 67.8192C53.9548 66.9713 53.4785 65.8214 53.4785 64.6223C53.4785 62.1563 57.9996 52.2921 57.9996 52.2921C57.9996 52.2921 62.5207 62.0823 62.5207 64.6223Z"
          fill="#F9C5C5"
        />
      </svg>
    ),
    shapeName: 'Culotte menstruelle Flux +',
    shapeDescription: `La culotte menstruelle pour flux léger offre une protection discrète et confortable. Avec ses couches absorbantes fines, elle garantit une sensation de fraîcheur et de sécurité pendant la journée. Idéale pour les flux légers, elle peut contenir l’équivalent d'1 tampon.`,
  },
  {
    picto: (
      <svg
        width="116"
        height="115"
        viewBox="0 0 116 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" transform="translate(0.5)" fill="#F9F9F9" />
        <g clipPath="url(#clip0_3774_1446)">
          <path
            d="M10.9253 54.621C10.2966 66.8021 10.811 82.2164 15.1707 96.0875"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M104.211 54.3434C105.762 67.4798 106.203 83.9309 101.713 96.0875"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M56.5 87.0001C56.5 87.0001 61 97.9728 56.5 104"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M59.8026 87.0001C59.8026 87.0001 58.8939 90.1582 58.2149 93.5087"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M58.1715 87.1068H56.6552C34.3246 66.2871 11.0879 59.2715 11.0879 54.4495C11.0879 54.4495 15.0966 27.2542 16.4274 20.7309C16.5038 20.342 16.7191 19.9942 17.0331 19.7524C17.347 19.5105 17.7382 19.3911 18.1337 19.4164C24.061 19.7348 48.0559 21.0085 57.6245 20.9758C66.9155 20.935 89.2367 19.7185 94.7477 19.4082C95.128 19.383 95.5052 19.4916 95.8139 19.7153C96.1225 19.939 96.3432 20.2636 96.4377 20.6329C98.1848 26.9521 104.161 54.4577 104.161 54.4577C104.161 56.642 83.0101 59.3262 59.6022 87.1149H58.1715V87.1068Z"
            stroke="black"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M56.5304 64.6223C56.5304 65.8214 56.0541 66.9713 55.2062 67.8192C54.3584 68.667 53.2084 69.1434 52.0094 69.1434C50.8103 69.1434 49.6603 68.667 48.8125 67.8192C47.9646 66.9713 47.4883 65.8214 47.4883 64.6223C47.4883 62.1563 52.0094 52.2921 52.0094 52.2921C52.0094 52.2921 56.5304 62.0823 56.5304 64.6223Z"
          fill="#F9C5C5"
        />
        <path
          d="M68.5148 64.6223C68.5148 65.8214 68.0385 66.9713 67.1906 67.8192C66.3427 68.667 65.1928 69.1434 63.9937 69.1434C62.7947 69.1434 61.6447 68.667 60.7968 67.8192C59.949 66.9713 59.4727 65.8214 59.4727 64.6223C59.4727 62.1563 63.9937 52.2921 63.9937 52.2921C63.9937 52.2921 68.5148 62.0823 68.5148 64.6223Z"
          fill="#F9C5C5"
        />
      </svg>
    ),
    shapeName: 'Culotte menstruelle Flux ++',
    shapeDescription: `Idéale pour les flux modérés, elle combine praticité et confort. Elle peut absorber l’équivalent de 2 à 3 tampons.`,
  },
  {
    picto: (
      <svg
        width="116"
        height="115"
        viewBox="0 0 116 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" transform="translate(0.5)" fill="#F9F9F9" />
        <g clipPath="url(#clip0_3774_1432)">
          <path
            d="M10.9253 54.621C10.2966 66.8021 10.811 82.2164 15.1707 96.0875"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M104.211 54.3434C105.762 67.4798 106.203 83.9309 101.713 96.0875"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M56.5 87.0001C56.5 87.0001 61 97.9728 56.5 104"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M59.8026 87.0001C59.8026 87.0001 58.8939 90.1582 58.2149 93.5087"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M58.1715 87.1068H56.6552C34.3246 66.2871 11.0879 59.2715 11.0879 54.4495C11.0879 54.4495 15.0966 27.2542 16.4274 20.7309C16.5038 20.342 16.7191 19.9942 17.0331 19.7524C17.347 19.5105 17.7382 19.3911 18.1337 19.4164C24.061 19.7348 48.0559 21.0085 57.6245 20.9758C66.9155 20.935 89.2367 19.7185 94.7477 19.4082C95.128 19.383 95.5052 19.4916 95.8139 19.7153C96.1225 19.939 96.3432 20.2636 96.4377 20.6329C98.1848 26.9521 104.161 54.4577 104.161 54.4577C104.161 56.642 83.0101 59.3262 59.6022 87.1149H58.1715V87.1068Z"
            stroke="black"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M50.5363 64.6223C50.5363 65.8214 50.06 66.9713 49.2121 67.8192C48.3642 68.667 47.2143 69.1434 46.0152 69.1434C44.8161 69.1434 43.6662 68.667 42.8183 67.8192C41.9705 66.9713 41.4941 65.8214 41.4941 64.6223C41.4941 62.1563 46.0152 52.2921 46.0152 52.2921C46.0152 52.2921 50.5363 62.0823 50.5363 64.6223Z"
          fill="#F9C5C5"
        />
        <path
          d="M62.5207 64.6223C62.5207 65.8214 62.0443 66.9713 61.1965 67.8192C60.3486 68.667 59.1987 69.1434 57.9996 69.1434C56.8005 69.1434 55.6506 68.667 54.8027 67.8192C53.9548 66.9713 53.4785 65.8214 53.4785 64.6223C53.4785 62.1563 57.9996 52.2921 57.9996 52.2921C57.9996 52.2921 62.5207 62.0823 62.5207 64.6223Z"
          fill="#F9C5C5"
        />
        <path
          d="M74.505 64.6223C74.505 65.8214 74.0287 66.9713 73.1808 67.8192C72.333 68.667 71.183 69.1434 69.984 69.1434C68.7849 69.1434 67.6349 68.667 66.7871 67.8192C65.9392 66.9713 65.4629 65.8214 65.4629 64.6223C65.4629 62.1563 69.984 52.2921 69.984 52.2921C69.984 52.2921 74.505 62.0823 74.505 64.6223Z"
          fill="#F9C5C5"
        />
      </svg>
    ),
    shapeName: 'Culotte menstruelle Flux +++',
    shapeDescription: `La culotte menstruelle pour flux +++ offre une protection maximale avec des couches absorbantes et anti-fuites. Confortable et discrète, elle reste au sec toute la journée, idéale pour un flux abondant. Elle peut contenir l’équivalent de plus de 7 tampons (45ml) tout en conservant ses 2 millimètres d’épaisseur.`,
  },
  {
    picto: (
      <svg
        width="115"
        height="115"
        viewBox="0 0 115 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" fill="#F9F9F9" />
        <g clipPath="url(#clip0_1016_1141)">
          <path
            d="M97.247 50.7866V21.3868H94.1523V50.7866C74.8103 58.5234 60.8841 75.5443 60.8841 75.5443H56.6289C56.6289 75.5443 42.7026 58.5234 23.3607 50.7866V21.3868H20.266V50.7866C20.266 50.7866 20.266 60.8445 12.916 61.2313L13.6897 85.989C14.5291 85.9006 15.3777 85.9929 16.1784 86.2598C16.9791 86.5267 17.7133 86.962 18.3318 87.5364C18.3318 87.5364 35.7395 101.849 52.3736 85.989H65.1393C81.3866 101.849 99.1812 87.5364 99.1812 87.5364C100.729 86.3758 103.823 85.989 103.823 85.989L104.597 61.2313C97.247 60.8445 97.247 50.7866 97.247 50.7866Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M15.6246 62.3918C15.1449 62.8715 13.7291 74.9718 19.0984 83.6912C20.5144 85.963 22.5598 87.7746 24.9861 88.9058C31.6165 92.0005 47.1056 96.3872 56.6295 75.5443"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M101.742 62.3918C102.229 62.8792 103.691 75.3587 98.0125 84.0935C96.6478 86.158 94.7387 87.8047 92.4962 88.8516C85.9354 91.9463 70.3226 96.511 60.7754 75.5443"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M59.7227 73.997C61.5311 69.0225 64.4079 64.5048 68.1507 60.762C71.8935 57.0193 76.4111 54.1424 81.3857 52.334"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M57.4032 73.997C55.5948 69.0225 52.718 64.5048 48.9752 60.762C45.2324 57.0193 40.7148 54.1424 35.7402 52.334"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    ),
    shapeName: 'Soutien-gorge emboîtant',
    shapeDescription: `Le soutien-gorge emboîtant offre un excellent maintien du bonnet B au H. Il emboîte parfaitement le sein et sublime la poitrine en la maintenant haute et soutenue.`,
  },
  {
    picto: (
      <svg
        width="116"
        height="115"
        viewBox="0 0 116 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" transform="translate(0.5)" fill="#F9F9F9" />
        <g clipPath="url(#clip0_3774_1586)">
          <path
            d="M30.5605 37.481C31.7852 39.9069 36.1417 47.498 42.8306 47.498C50.3095 47.498 52.1792 46.0957 56.8536 40.0191"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path d="M43.7642 76.0113L40.4922 47.0305" stroke="black" strokeMiterlimit="10" />
          <path
            d="M86.3014 31.1379L82.5619 25.9961V8.23376H81.1596V24.5939C81.1596 24.5939 63.3972 33.0076 59.1904 40.0191H56.8532C52.6463 33.0076 34.8839 24.5939 34.8839 24.5939V8.23376H33.4816V25.9961L29.7422 31.1379L35.3514 74.609C36.4358 75.4737 47.0371 76.9461 58.2555 76.9461C69.1046 76.9461 77.7754 75.4457 78.8225 74.609L86.3014 31.1379Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M85.0758 38.2522C83.4585 41.211 79.303 47.498 73.2124 47.498C65.7335 47.498 63.8638 46.0957 59.1895 40.0191"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path d="M72.2793 76.0113L75.5513 47.0305" stroke="black" strokeMiterlimit="10" />
          <path d="M58.0215 39.8789L58.2552 77.1799" stroke="black" strokeMiterlimit="10" />
          <path
            d="M46.5688 80.2182C46.5688 80.2182 43.2968 84.8925 44.2316 105.459H42.8293C43.2968 85.3599 40.4922 79.7507 40.4922 79.7507"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M35.3518 74.609L34.1738 78.1147C38.1709 79.4453 42.3485 80.1547 46.5608 80.2182"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M44.2324 105.459V106.838L42.8301 106.862V105.459"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M74.7319 79.9283C73.9934 82.3076 72.3947 89.3798 72.7453 104.992H71.343C72.0862 88.6319 69.8239 82.1206 69.0059 80.2181"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M71.3438 104.992V106.371L72.746 106.394V104.992"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M46.5703 80.2182C46.5703 80.2182 55.4936 79.517 58.4805 79.5404C60.8176 79.5404 67.8291 80.2182 67.8291 80.2182C76.0091 80.6856 80.216 77.881 80.216 77.881L78.8137 74.609"
            stroke="black"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    ),
    shapeName: 'Guêpière',
    shapeDescription: `La guêpière sculpte la taille, rehausse la poitrine et offre un maintien optimal, créant une silhouette en sablier. Elle est idéale pour les occasions spéciales ou pour se sentir plus féminine au quotidien.`,
  },
  {
    picto: (
      <svg
        width="115"
        height="115"
        viewBox="0 0 115 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" fill="#F9F9F9" />
        <g clipPath="url(#clip0_1016_1204)">
          <path
            d="M57.8965 83.6875C57.8965 83.6875 57.8965 63.1042 80.8548 54.3958"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M57.8965 83.6875C57.8965 83.6875 57.8965 63.8958 34.1465 55.9792"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M46.0208 61.5208L55.5208 52.8125L20.6875 21.9375V45.2917"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M11.9806 60.7292C11.9806 60.7292 11.1889 86.0625 25.4389 93.9792C25.4389 93.9792 40.4806 102.687 55.5222 85.2708"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M55.5215 85.2708C55.5215 85.2708 54.7298 75.7708 37.3132 63.8958C37.3132 63.8958 22.2715 52.8125 21.4798 47.2708L17.9173 47.6667V53.2083L10.7923 61.9167L10.3965 74.9792L15.1465 90.8125H17.5215C17.5215 90.8125 33.3548 108.229 53.9382 90.0208H55.3711"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M59.4805 90.0208H61.0638C81.6471 108.229 97.4805 90.8125 97.4805 90.8125H99.8555L104.605 74.9792L104.21 61.9167L97.0846 53.2083V47.6667L93.5221 47.2708C92.7305 52.8125 77.6888 63.8958 77.6888 63.8958C60.2721 75.7708 59.4805 85.2708 59.4805 85.2708"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M48.3965 63.8958L57.5007 55.5517L66.6048 63.8958"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M97.4805 44.8958L98.2721 16.3958H95.1055L57.5013 50.865L19.8971 16.3958H16.7305L17.5221 44.8958"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M68.9805 61.5208L59.4805 52.8125L94.3138 21.9375V45.2917"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M103.022 60.7292C103.022 60.7292 103.814 86.0625 89.5638 93.9792C89.5638 93.9792 74.5221 103.479 59.4805 85.2708"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M98.0742 44.8958H93.7201C93.1735 44.8958 92.7305 45.3389 92.7305 45.8854V46.2812C92.7305 46.8278 93.1735 47.2708 93.7201 47.2708H98.0742C98.6208 47.2708 99.0638 46.8278 99.0638 46.2812V45.8854C99.0638 45.3389 98.6208 44.8958 98.0742 44.8958Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M16.9277 47.2708H21.2819C21.8284 47.2708 22.2715 46.8278 22.2715 46.2812V45.8854C22.2715 45.3389 21.8284 44.8958 21.2819 44.8958H16.9277C16.3812 44.8958 15.9382 45.3389 15.9382 45.8854V46.2812C15.9382 46.8278 16.3812 47.2708 16.9277 47.2708Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M55.5215 84.6771V90.6146C55.5215 91.1611 55.9645 91.6042 56.5111 91.6042H58.4902C59.0368 91.6042 59.4798 91.1611 59.4798 90.6146V84.6771C59.4798 84.1305 59.0368 83.6875 58.4902 83.6875H56.5111C55.9645 83.6875 55.5215 84.1305 55.5215 84.6771Z"
            stroke="black"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    ),
    shapeName: 'Soutien-gorge multipositions',
    shapeDescription: `Il s'adapte à toutes les formes d'encolures (dos nu, bustier, asymétrique) grâce à ses bretelles réglables et amovibles, permettant de créer de nombreux looks.`,
  },
  {
    picto: (
      <svg
        width="115"
        height="115"
        viewBox="0 0 115 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" fill="#F9F9F9" />
        <g clipPath="url(#clip0_1016_1366)">
          <path
            d="M11.3307 36.5082C9.90041 49.3234 8.86467 71.5424 14.6188 89.939"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M101.752 32.3981C104.07 46.0846 107.243 74.0084 101.752 89.939"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M62.5176 80.8969C62.5176 80.8969 78.7359 91.583 94.3541 75.1428"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M21.416 75.1428C37.0343 91.583 53.2526 80.8969 53.2526 80.8969"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M53.3359 80.8969C53.3359 80.8969 55.054 86.7332 53.3359 89.939"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M62.32 80.8969C62.32 80.8969 60.5937 86.7332 62.32 89.939"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M57.9147 80.8969H52.9827C26.9495 68.5667 17.0854 52.9484 12.1944 39.4263C11.8046 38.5589 11.5709 37.6295 11.5039 36.6808L13.1479 26.3974C13.1479 26.14 13.2484 25.8928 13.4281 25.7085C13.6078 25.5242 13.8524 25.4174 14.1097 25.411H99.673C99.9346 25.411 100.186 25.5149 100.37 25.6999C100.555 25.8849 100.659 26.1358 100.659 26.3974L102.303 36.6808C102.224 37.6274 101.99 38.5546 101.613 39.4263C99.2866 46.3723 93.5326 61.1686 62.518 80.8969H57.9147Z"
            stroke="black"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    ),
    shapeName: 'Panty',
    shapeDescription: `Le panty est une lingerie qui couvre le ventre, les hanches et les cuisses, offrant un maintien léger à modéré. Il sculpte la silhouette, lissant les courbes tout en garantissant confort et discrétion sous les vêtements.`,
  },
  {
    picto: (
      <svg
        width="116"
        height="115"
        viewBox="0 0 116 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" transform="translate(0.5)" fill="#F9F9F9" />
        <g clipPath="url(#clip0_3814_725)">
          <path
            d="M58.3238 80.6221C58.3238 80.6221 38.2266 106.061 19.7828 84.0905C18.0328 82.0008 17.0684 79.3651 17.0566 76.6395C17.0566 69.5044 17.6665 54.7934 23.3026 49.4513C23.4357 49.3375 23.605 49.2749 23.7802 49.2749C23.9553 49.2749 24.1247 49.3375 24.2578 49.4513C28.0642 52.4567 52.8862 72.4878 58.3238 80.6221Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M25.0585 42.4118H19.5474C19.0401 42.4118 18.6289 42.823 18.6289 43.3303V43.6977C18.6289 44.205 19.0401 44.6162 19.5474 44.6162H25.0585C25.5658 44.6162 25.977 44.205 25.977 43.6977V43.3303C25.977 42.823 25.5658 42.4118 25.0585 42.4118Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path d="M24.1409 42.4118V20.3674H20.4668V42.4118" stroke="black" strokeMiterlimit="10" />
          <path
            d="M97.6365 44.6163C97.6365 44.6163 101.362 58.5043 102.964 60.2605C103.076 60.3884 103.136 60.5534 103.133 60.7234L101.678 88.3377C101.678 88.3377 96.8429 89.3591 93.9624 91.6444C89.1935 95.4213 81.5882 96.4721 69.7136 93.8488C66.4069 93.114 58.324 93.114 58.324 93.114C53.9151 93.114 49.9618 93.114 47.3018 93.8488C35.5447 97.1555 27.8071 95.4213 23.0529 91.6444C20.1577 89.3591 15.3227 88.3377 15.3227 88.3377L13.8677 60.7234C13.8607 60.5528 13.9215 60.3864 14.0367 60.2605C15.6386 58.5043 19.3788 44.6163 19.3788 44.6163"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M58.6914 80.6221C58.6914 80.6221 78.7739 106.061 97.2177 84.0905C98.9677 82.0008 99.9321 79.3651 99.9439 76.6394C99.9439 69.5044 99.334 54.7934 93.6979 49.4513C93.5643 49.3355 93.3934 49.2718 93.2166 49.2718C93.0398 49.2718 92.8689 49.3355 92.7353 49.4513C88.9363 52.4567 64.1143 72.4877 58.6914 80.6221Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M97.7804 76.0637C97.7698 78.5034 96.9066 80.8626 95.3402 82.7331C86.8581 92.8372 77.9889 91.7392 71.3693 88.2827C67.7752 86.406 66.8865 81.8929 69.0558 78.4674C70.9686 75.4467 73.6082 71.7043 76.9445 67.7819C80.7286 63.333 85.4674 60.982 89.4534 59.8417C93.2154 58.7654 96.6848 61.4736 97.1742 65.3559C97.6809 69.3743 97.7804 73.337 97.7804 76.0637Z"
            stroke="black"
            strokeMiterlimit="10"
            strokeDasharray="1.99 1.99"
          />
          <path
            d="M91.7585 44.6163C77.4296 54.9037 58.3244 76.9481 58.3244 76.9481C58.3244 76.9481 39.9541 54.9037 25.2578 44.6163"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M91.9415 44.6163H97.4526C97.9599 44.6163 98.3711 44.205 98.3711 43.6978V43.3303C98.3711 42.8231 97.9599 42.4118 97.4526 42.4118H91.9415C91.4342 42.4118 91.0229 42.8231 91.0229 43.3303V43.6978C91.0229 44.205 91.4342 44.6163 91.9415 44.6163Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path d="M92.8594 42.4118V20.3674H96.5335V42.4118" stroke="black" strokeMiterlimit="10" />
        </g>
      </svg>
    ),
    shapeName: 'Soutien-gorge post-opération',
    shapeDescription: `Le soutien-gorge post-opératoire offre un soutien personnalisé et favorise une cicatrisation optimale. Il réduit la douleur, l'œdème et maintient les implants ou les tissus en place, contribuant à un résultat esthétique harmonieux.`,
  },
  {
    picto: (
      <svg
        width="115"
        height="115"
        viewBox="0 0 115 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" fill="#F9F9F9" />
        <g clipPath="url(#clip0_1016_1238)">
          <path
            d="M96.194 52.9244L98.4629 17.3782H94.6814L93.547 51.7899C69.3453 61.6218 58.7571 84.3109 58.7571 84.3109H57.2445C57.2445 84.3109 46.6562 61.6218 22.4545 51.7899L21.3201 17.3782H17.5386L19.8075 52.9244C19.8075 52.9244 14.8915 60.8655 13.3789 62.3782V75.9916L16.4041 90.3613C16.4041 90.3613 20.1856 90.3613 20.9419 91.1176C20.9419 91.1176 38.3369 107 53.4629 89.605H62.5386C77.6646 107 95.0596 91.1176 95.0596 91.1176C95.8159 90.3613 99.5974 90.3613 99.5974 90.3613L102.623 75.9916V62.3782C101.11 60.8655 96.194 52.9244 96.194 52.9244Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M14.1348 61.6218C14.1348 61.6218 14.1348 85.8235 27.0373 93.0235C27.6986 93.3846 28.3956 93.6761 29.1171 93.8933C33.3146 95.2546 46.2171 97.9244 54.9751 83.5546"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M101.866 61.6218C101.866 61.6218 101.866 85.8235 88.9632 93.0235C88.3019 93.3846 87.6049 93.6761 86.8834 93.8933C82.6859 95.2546 69.7834 97.9244 61.0254 83.5546"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M58 81.2857C58 81.2857 58 51.0336 87.4958 50.2773"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M58.0001 81.2857C58.0001 81.2857 58.3971 73.3461 54.5135 65.5C50.8163 58.0307 43.2397 50.6462 27.748 50.2773"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    ),
    shapeName: 'Soutien-gorge push-up',
    shapeDescription: `Le soutien-gorge push-up augmente le volume de la poitrine et la recentre au cœur du décolleté. Idéal pour les poitrines menues qui souhaitent booster leur décolleté.`,
  },
  {
    picto: (
      <svg
        width="116"
        height="115"
        viewBox="0 0 116 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" transform="translate(0.5)" fill="#F9F9F9" />
        <g clipPath="url(#clip0_3774_1602)">
          <path
            d="M30.7768 94.9078C30.7768 94.9078 25.2129 86.9593 25.2129 79.0109"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M88.0059 94.9078C88.0059 94.9078 93.5698 86.9593 92.7749 79.0109"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M97.1391 55.9604L100.318 19.3975H97.1391L94.3651 55.563C94.3651 55.563 63.3661 81.6895 58.597 88.5491C53.8518 81.6895 21.6287 55.563 21.6287 55.563L18.8547 19.3975H15.6753L18.8547 55.9604C14.0856 60.7295 10.9062 70.2676 10.9062 70.2676L12.8854 91.331C25.2055 97.1652 47.9938 94.7568 55.9422 93.7156C57.7014 93.4771 59.4847 93.4771 61.2439 93.7156C69.0095 94.7807 90.8201 97.1573 103.1 91.331L105.088 70.2676C105.088 70.2676 101.908 60.7295 97.1391 55.9604Z"
            stroke="black"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    ),
    shapeName: 'Soutien-gorge sans-armature',
    shapeDescription: `Le soutien-gorge sans-armature offre un confort absolu pour un rendu naturel. Forme parfaite pour habiller les petites poitrines et peut également convenir aux poitrines plus généreuses.`,
  },
  {
    picto: (
      <svg
        width="115"
        height="115"
        viewBox="0 0 115 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" fill="#F9F9F9" />
        <g clipPath="url(#clip0_1016_1392)">
          <path
            d="M11.3847 36.6753C9.90992 49.342 8.78745 70.8818 14.6865 89.7262"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M102.15 37.1341C104.452 50.8003 106.984 73.8724 101.535 89.6852"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M62.4355 80.7137C62.4355 80.7137 78.6089 91.3648 94.1759 74.9784"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M21.4707 74.9784C37.0378 91.3648 53.2111 80.7137 53.2111 80.7137"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M53.2676 80.7137C53.2676 80.7137 54.9881 86.5308 53.2676 89.7262"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M62.2239 80.7137C62.2239 80.7137 60.5116 86.5308 62.2239 89.7262"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M57.84 80.7137H52.8749C33.5308 50.3989 17.1445 44.6636 12.2695 39.379C11.7095 38.5834 11.4643 37.6084 11.5813 36.6425L13.2199 26.3928C13.2188 26.2641 13.2433 26.1364 13.2918 26.0172C13.3403 25.898 13.4119 25.7895 13.5026 25.6981C13.5932 25.6067 13.7011 25.5342 13.8199 25.4846C13.9387 25.4351 14.0662 25.4096 14.1949 25.4097H99.4614C99.7222 25.4097 99.9722 25.5132 100.157 25.6976C100.341 25.882 100.445 26.1321 100.445 26.3928L102.083 36.6425C102.083 37.6011 102.132 38.7727 101.395 39.379C94.9797 44.6636 81.0513 50.3989 62.4364 80.7137H57.84Z"
            stroke="black"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    ),
    shapeName: 'Slip',
    shapeDescription: `Le slip est souvent plus échancré qu'une culotte et plus couvrant qu'un tanga. C'est le parfait compromis entre confort et sensualité.`,
  },
  {
    picto: (
      <svg
        width="115"
        height="115"
        viewBox="0 0 115 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" fill="#F9F9F9" />
        <g clipPath="url(#clip0_1016_1415)">
          <path
            d="M12.0317 31.4052C10.3861 43.3028 7.98358 68.2746 14.5001 89.0009"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M100.895 28.114C103.281 40.5546 107.888 71.0886 101.717 89.0009"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M53.9941 70.8994C53.9941 70.8994 54.8169 87.3553 54.0682 90.6464"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M59.293 70.8994C59.293 70.8994 58.4702 87.3553 59.2189 90.6464"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M56.4621 53.7934C56.4621 53.7934 62.2217 88.1369 24.373 76.6178"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M56.7745 53.7934C56.7745 53.7934 51.0149 88.1781 88.8635 76.6589"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M54.8164 69.2538C55.307 69.7264 55.9618 69.9904 56.643 69.9904C57.3243 69.9904 57.979 69.7264 58.4696 69.2538"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M101.436 31.4052C101.436 31.4052 66.8788 31.076 57.285 51.9751L56.5363 53.8346C46.4406 33.6843 12.0312 32.228 12.0312 32.228L13.0268 25.3987C13.0257 25.2695 13.0503 25.1413 13.099 25.0215C13.1477 24.9018 13.2197 24.7929 13.3107 24.7011C13.4017 24.6093 13.51 24.5364 13.6294 24.4867C13.7487 24.437 13.8767 24.4114 14.006 24.4114H99.6343C99.7636 24.4114 99.8916 24.437 100.011 24.4867C100.13 24.5364 100.239 24.6093 100.33 24.7011C100.421 24.7929 100.493 24.9018 100.541 25.0215C100.59 25.1413 100.615 25.2695 100.613 25.3987L101.436 31.5779"
            stroke="black"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    ),
    shapeName: 'String',
    shapeDescription: `Le string possède une coupe encore plus échancrée que celle d'un tanga, c'est un sous-vêtement minimaliste qui est presque invisible sous les vêtements serrés`,
  },
  {
    picto: (
      <svg
        width="115"
        height="115"
        viewBox="0 0 115 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="115" height="115" fill="#F9F9F9" />
        <g clipPath="url(#clip0_1016_1439)">
          <path
            d="M12.0279 32.3898C10.3858 44.2545 7.98819 69.1664 14.4912 89.866"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M101.33 32.6197C103.719 45.1413 107.652 72.032 101.527 89.825"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M59.6504 80.8341C59.6504 80.8341 88.3885 93.9715 99.0627 70.1599"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M18.5957 70.1599C25.1644 93.1504 55.5447 80.8341 55.5447 80.8341"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M55.6191 80.834C55.6191 80.834 56.3663 86.5817 55.6191 89.866"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M59.6761 80.834C59.2017 83.826 59.2017 86.8741 59.6761 89.866"
            stroke="#E00730"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M57.7374 80.834H56.1527C55.9859 80.8324 55.8258 80.7683 55.7042 80.6542C55.5825 80.5401 55.5081 80.3845 55.4958 80.2182C52.2114 38.0553 12.0273 34.1223 12.0273 33.2109L13.0209 26.3958C13.0198 26.2668 13.0443 26.1389 13.0929 26.0194C13.1415 25.8999 13.2133 25.7912 13.3041 25.6996C13.395 25.608 13.503 25.5353 13.6221 25.4857C13.7412 25.4361 13.8689 25.4105 13.998 25.4105H99.4487C99.71 25.4105 99.9606 25.5143 100.145 25.6991C100.33 25.8839 100.434 26.1345 100.434 26.3958L101.255 32.5622C101.255 33.5147 59.0429 38.86 59.6341 80.1115C59.6363 80.1995 59.6209 80.2871 59.589 80.3692C59.5571 80.4512 59.5092 80.5261 59.4481 80.5895C59.387 80.6529 59.3139 80.7035 59.233 80.7385C59.1522 80.7734 59.0653 80.7919 58.9772 80.793H57.7374V80.834Z"
            stroke="black"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    ),
    shapeName: 'Tanga',
    shapeDescription: `Le tanga se caractérise par une coupe très échancrée à l'arrière. L'avant, quant à lui, ressemble à celui d'une culotte classique, offrant un bon confort.`,
  },
];
